import graphql from 'babel-plugin-relay/macro'
import { OpenAI } from 'openai'
import React, { Component } from 'react'
import { Form } from 'react-bootstrap'
import { commitMutation, createFragmentContainer } from 'react-relay'

import environment from '../Environment'
import { slugify, theme } from '../helpers'

const sanitize = ({
  name,
  description,
  facebookUrl,
  youtubeUrl,
  twitterUrl,
  instagramUrl,
  linkedinUrl,
  vimeoUrl,
  siteUrl,
  mobileSiteUrl,
  iosAppUrl,
  androidAppUrl,
  slug,
  phone,
  email,
  type,
  address,
  latitude,
  longitude,
  country
}) => ({
  name,
  description,
  facebookUrl,
  youtubeUrl,
  twitterUrl,
  instagramUrl,
  linkedinUrl,
  vimeoUrl,
  siteUrl,
  mobileSiteUrl,
  iosAppUrl,
  androidAppUrl,
  slug,
  phone,
  email,
  type,
  address,
  latitude,
  longitude,
  country
})

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
})

class Hub extends Component {
  constructor (props) {
    super(props)

    this.state = {
      form: {
        ...sanitize(props.hub || {})
      }
    }
  }

  update = () => {
    const mutation = graphql`
      mutation HubUpdateMutation($input: UpdateHubByIdInput!) {
        updateHubById(input: $input) {
          hub {
            id
          }
        }
      }
    `

    const variables = {
      input: {
        id: this.props.hub.id,
        hubPatch: {
          ...this.state.form,
          latitude: this.state.form.latitude
            ? parseFloat(this.state.form.latitude)
            : null,
          longitude: this.state.form.longitude
            ? parseFloat(this.state.form.longitude)
            : null,
          slug: slugify(this.state.form.slug) // ensure we are not messing up slug on update
        }
      }
    }

    return new Promise((resolve, reject) => {
      commitMutation(environment, {
        mutation,
        variables,
        onCompleted: (rsp, errors) => {
          // console.log(rsp, errors)
          if (errors) {
            console.log('failed', errors)
            reject(errors)
          } else {
            resolve(rsp)
          }
        },
        onError: err => {
          console.log('errored', err)
          reject(err)
        }
      })
    })
  }

  delete = () => {
    const mutation = graphql`
      mutation HubDeleteMutation($input: DeleteHubByIdInput!) {
        deleteHubById(input: $input) {
          hub {
            id
          }
        }
      }
    `

    const variables = {
      input: {
        id: this.props.hub.id
      }
    }

    return new Promise((resolve, reject) => {
      commitMutation(environment, {
        mutation,
        variables,
        onCompleted: (rsp, errors) => {
          if (errors) {
            console.log('failed', errors)
            reject(errors)
          } else {
            resolve(rsp)
          }
        },
        onError: err => {
          console.log('errored', err)
          reject(err)
        }
      })
    })
  }

  saveChanges = () => this.update()

  handleChange = hub => {
    const { name, value } = hub.target
    this.setState({ form: { ...this.state.form, [name]: value } })
  }

  renderField = (title, key, opts = {}) => {
    return (
      <Form.Group>
        <Form.Label style={theme.styles.label}>{title}</Form.Label>
        <Form.Control
          name={key}
          {...opts}
          placeholder={title}
          value={this.state.form[key] || ''}
          onChange={this.handleChange}
        />
      </Form.Group>
    )
  }

  renderHeader = title => <h5 style={theme.styles.header}>{title}</h5>

  render () {
    const { hub, mode } = this.props
    if (hub && !mode) {
      return <div>hmm</div>
    } else {
      const handleDescriptionGeneration = async e => {
        e.preventDefault()

        const response = await openai.chat.completions.create({
          model: 'gpt-3.5-turbo',
          temperature: 0.7,
          top_p: 1,
          frequency_penalty: 0.2,
          presence_penalty: 0,
          messages: [
            {
              role: 'system',
              content: `Write an objective but positive article in the language you speak in "${(
                this.state.form.address || ''
              )
                .split(',')
                .slice(-1)
                .join('')
                .trim()}", about a ${this.state.form.type
                .replace(/_/g, ' ')
                .toLowerCase()} named "${this.state.form.name}", located at ${
                this.state.form.address
              }`
            }
          ]
        })

        this.setState({
          form: {
            ...this.state.form,
            description: response.choices
              .map(c => c.message.content)
              .join('\n')
              .trim()
          }
        })
      }

      return (
        <Form>
          <Form.Group>
            <Form.Label style={theme.styles.label}>Name *</Form.Label>
            <Form.Control
              name='name'
              autoComplete='name'
              placeholder='Name'
              required
              value={this.state.form.name || ''}
              onChange={this.handleChange}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label style={theme.styles.label}>Description</Form.Label>
            <Form.Control
              style={{ height: 200 }}
              name='description'
              as='textarea'
              placeholder='Description'
              value={this.state.form.description || ''}
              onChange={this.handleChange}
            />
            <button
              style={{ marginTop: '8px' }}
              className='btn btn-secondary ml-auto'
              onClick={handleDescriptionGeneration}
            >
              Generate description
            </button>
          </Form.Group>

          {this.renderField('Slug', 'slug', { disabled: false })}

          <div className='form-group'>
            <label className='form-label' style={theme.styles.label}>
              Type
            </label>
            <select
              name='type'
              defaultValue={hub.type}
              className='form-control custom-select selectized'
              onChange={this.handleChange}
            >
              {[
                'ACCOUNTING',
                'AIRPORT',
                'AMUSEMENT_PARK',
                'AQUARIUM',
                'ARCADE',
                'ARCHIPELAGO',
                'ART_GALLERY',
                'ATM',
                'BAKERY',
                'BANK',
                'BAR',
                'BEAUTY_SALON',
                'BICYCLE_STORE',
                'BOOK_STORE',
                'BOWLING',
                'BOWLING_ALLEY',
                'BUS_STATION',
                'CAFE',
                'CAMPGROUND',
                'CAR_DEALER',
                'CAR_RENTAL',
                'CAR_REPAIR',
                'CAR_WASH',
                'CASINO',
                'CEMETERY',
                'CHURCH',
                'CITY_HALL',
                'CLOTHING_STORE',
                'COLLOQUIAL_AREA',
                'CONTINENT',
                'CONVENIENCE_STORE',
                'COUNTRY',
                'COURTHOUSE',
                'DENTIST',
                'DEPARTMENT_STORE',
                'DOCTOR',
                'DRUGSTORE',
                'ELECTRICIAN',
                'ELECTRONICS_STORE',
                'EMBASSY',
                'ENTERTAINMENT',
                'ESTABLISHMENT',
                'FINANCE',
                'FIRE_STATION',
                'FLOOR',
                'FLORIST',
                'FOOD',
                'FUNERAL_HOME',
                'FURNITURE_STORE',
                'GAS_STATION',
                'GENERAL_CONTRACTOR',
                'GEOCODE',
                'GROCERY_OR_SUPERMARKET',
                'GYM',
                'HAIR_CARE',
                'HARDWARE_STORE',
                'HEALTH',
                'HINDU_TEMPLE',
                'HOME_GOODS_STORE',
                'HOSPITAL',
                'INSURANCE_AGENCY',
                'INTERSECTION',
                'JEWELRY_STORE',
                'KID_CLASS',
                'KID_GYM',
                'LAUNDRY',
                'LAWYER',
                'LIBRARY',
                'LIGHT_RAIL_STATION',
                'LIQUOR_STORE',
                'LOCAL_GOVERNMENT_OFFICE',
                'LOCALITY',
                'LOCKSMITH',
                'LODGING',
                'MEAL_DELIVERY',
                'MEAL_TAKEAWAY',
                'MOSQUE',
                'MOVIE_RENTAL',
                'MOVIE_THEATER',
                'MOVING_COMPANY',
                'MUSEUM',
                'NATURAL_FEATURE',
                'NEIGHBORHOOD',
                'NIGHT_CLUB',
                'OTHER',
                'PAINTER',
                'PARK',
                'PARKING',
                'PET_STORE',
                'PHARMACY',
                'PHYSIOTHERAPIST',
                'PLACE_OF_WORSHIP',
                'PLAYGROUND',
                'PLUMBER',
                'PLUS_CODE',
                'POINT_OF_INTEREST',
                'POLICE',
                'POLITICAL',
                'POST_BOX',
                'POST_OFFICE',
                'POSTAL_CODE',
                'POSTAL_CODE_PREFIX',
                'POSTAL_CODE_SUFFIX',
                'POSTAL_TOWN',
                'PREMISE',
                'PRIMARY_SCHOOL',
                'REAL_ESTATE_AGENCY',
                'RESTAURANT',
                'ROOFING_CONTRACTOR',
                'ROOM',
                'ROUTE',
                'RV_PARK',
                'SCHOOL',
                'SECONDARY_SCHOOL',
                'SHOE_STORE',
                'SHOPPING_MALL',
                'SPA',
                'SPIRITUAL_CENTER',
                'STADIUM',
                'STORAGE',
                'STORE',
                'STREET_ADDRESS',
                'STREET_NUMBER',
                'SUBLOCALITY',
                'SUBPREMISE',
                'SUBWAY_STATION',
                'SUPERMARKET',
                'SYNAGOGUE',
                'TAXI_STAND',
                'THEME_PARK',
                'TOURIST_ATTRACTION',
                'TOWN_SQUARE',
                'TRAIN_STATION',
                'TRANSIT_STATION',
                'TRAVEL_AGENCY',
                'UNIVERSITY',
                'VETERINARY_CARE',
                'ZOO'
              ].map(type => (
                <option key={type}>{type}</option>
              ))}
            </select>
          </div>

          {this.renderHeader('Contact')}
          {this.renderField('Phone', 'phone', {
            type: 'phone',
            autoComplete: 'tel-national'
          })}
          {this.renderField('Email', 'email', {
            type: 'email',
            autoComplete: 'email'
          })}

          {this.renderHeader('Address')}
          {this.renderField('Address', 'address', {
            autoComplete: 'address-line1'
          })}
          {this.renderField('Latitude', 'latitude')}
          {this.renderField('Longitude', 'longitude')}
          {this.renderField('Country', 'country')}

          {this.renderHeader('Website')}
          {this.renderField('Site Url', 'siteUrl', { type: 'url' })}
          {this.renderField('Mobile Site Url', 'mobileSiteUrl', {
            type: 'url'
          })}
          {this.renderField('Ios Url', 'iosAppUrl', { type: 'url' })}
          {this.renderField('Android Url', 'androidAppUrl', { type: 'url' })}

          {this.renderHeader('Social Links')}
          {this.renderField('Facebook', 'facebookUrl', { type: 'url' })}
          {this.renderField('YouTube', 'youtubeUrl', { type: 'url' })}
          {this.renderField('Twitter', 'twitterUrl', { type: 'url' })}
          {this.renderField('Instagram', 'instagramUrl', { type: 'url' })}
          {this.renderField('LinkedIn', 'linkedinUrl', { type: 'url' })}
          {this.renderField('Vimeo', 'vimeoUrl', { type: 'url' })}
        </Form>
      )
    }
  }
}

const HubContainer = createFragmentContainer(Hub, {
  hub: graphql`
    fragment Hub_hub on Hub {
      id
      name
      description
      slug
      phone
      email
      type
      address
      latitude
      longitude
      country
      facebookUrl
      youtubeUrl
      twitterUrl
      instagramUrl
      linkedinUrl
      vimeoUrl
      siteUrl
      mobileSiteUrl
      iosAppUrl
      androidAppUrl
    }
  `
})

export { HubContainer as Hub }
