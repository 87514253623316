/**
 * @generated SignedSource<<a0da7f178d82035aed247c4746e60a2f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": "NAME_ASC"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "latitude",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "longitude",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PartnersQuery",
    "selections": [
      {
        "alias": "partners",
        "args": (v0/*: any*/),
        "concreteType": "PartnersConnection",
        "kind": "LinkedField",
        "name": "allPartners",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PartnersList_partners"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PartnersMap_partners"
          }
        ],
        "storageKey": "allPartners(orderBy:\"NAME_ASC\")"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PartnersQuery",
    "selections": [
      {
        "alias": "partners",
        "args": (v0/*: any*/),
        "concreteType": "PartnersConnection",
        "kind": "LinkedField",
        "name": "allPartners",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PartnersEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Partner",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "phone",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "country",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "logoUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "siteUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Partner",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": "allPartners(orderBy:\"NAME_ASC\")"
      }
    ]
  },
  "params": {
    "cacheID": "9dcebad350b06ed4213bd73f6c25197f",
    "id": null,
    "metadata": {},
    "name": "PartnersQuery",
    "operationKind": "query",
    "text": "query PartnersQuery {\n  partners: allPartners(orderBy: NAME_ASC) {\n    totalCount\n    ...PartnersList_partners\n    ...PartnersMap_partners\n  }\n}\n\nfragment PartnersList_partners on PartnersConnection {\n  totalCount\n  edges {\n    node {\n      id\n      name\n      description\n      phone\n      email\n      latitude\n      longitude\n      country\n      logoUrl\n      siteUrl\n      type\n    }\n  }\n}\n\nfragment PartnersMap_partners on PartnersConnection {\n  totalCount\n  nodes {\n    id\n    name\n    latitude\n    longitude\n  }\n}\n"
  }
};
})();

node.hash = "13ffe2c51a132de32d4be314b2e643d1";

module.exports = node;
