/**
 * @generated SignedSource<<de170b470c3b4869e0bb7bce71a05064>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pictureUrl",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pictureThumbnailUrl",
  "storageKey": null
},
v7 = {
  "alias": "user",
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "userByUserId",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/),
    (v6/*: any*/)
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EventsShowQuery",
    "selections": [
      {
        "alias": "event",
        "args": (v1/*: any*/),
        "concreteType": "Event",
        "kind": "LinkedField",
        "name": "eventById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Event_event"
          },
          {
            "alias": "hosts",
            "args": null,
            "concreteType": "HostsConnection",
            "kind": "LinkedField",
            "name": "hostsByEventId",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Host",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": "guests",
            "args": null,
            "concreteType": "GuestsConnection",
            "kind": "LinkedField",
            "name": "guestsByEventId",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "GuestsList_guests"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EventsShowQuery",
    "selections": [
      {
        "alias": "event",
        "args": (v1/*: any*/),
        "concreteType": "Event",
        "kind": "LinkedField",
        "name": "eventById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startsAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endsAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "latitude",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "longitude",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "address",
            "storageKey": null
          },
          {
            "alias": "hosts",
            "args": null,
            "concreteType": "HostsConnection",
            "kind": "LinkedField",
            "name": "hostsByEventId",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Host",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": "guests",
            "args": null,
            "concreteType": "GuestsConnection",
            "kind": "LinkedField",
            "name": "guestsByEventId",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Guest",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "updatedAt",
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": "user",
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "userByUserId",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v8/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "response",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8b26f7c491b4d8d66db24e42e24f5af5",
    "id": null,
    "metadata": {},
    "name": "EventsShowQuery",
    "operationKind": "query",
    "text": "query EventsShowQuery(\n  $id: Uuid!\n) {\n  event: eventById(id: $id) {\n    title\n    ...Event_event\n    hosts: hostsByEventId {\n      nodes {\n        user: userByUserId {\n          id\n          firstName\n          pictureUrl\n          pictureThumbnailUrl\n        }\n        id\n      }\n    }\n    guests: guestsByEventId {\n      ...GuestsList_guests\n    }\n    id\n  }\n}\n\nfragment Event_event on Event {\n  id\n  title\n  description\n  startsAt\n  endsAt\n  latitude\n  longitude\n  address\n}\n\nfragment GuestsList_guests on GuestsConnection {\n  nodes {\n    id\n    updatedAt\n    firstName\n    lastName\n    user: userByUserId {\n      id\n      firstName\n      lastName\n      pictureUrl\n      pictureThumbnailUrl\n    }\n    response\n  }\n}\n"
  }
};
})();

node.hash = "1cb5e6151556a340b14f2d6679f65408";

module.exports = node;
