import graphql from 'babel-plugin-relay/macro'
import React, { Component } from 'react'
import { Button, Col, Container, FormGroup } from 'react-bootstrap'
import { QueryRenderer } from 'react-relay'

import environment from '../../Environment'
import { Error, Event } from '../../components'

class EventsNew extends Component {
  onCreate = () => {
    console.log('creating event')
  }

  render () {
    return (
      <QueryRenderer
        environment={environment}
        query={graphql`
          query EventsNewQuery {
            version
          }
        `}
        variables={{ }}
        render={({error, props}) => {
          if (error) return <Error error={error} />
          
          if (props) {
            return (
              <Container>
                <Event event={null} />

                <FormGroup>
                  <Col>
                    <Button className="float-right" bsStyle='success' type='submit' onClick={this.onCreate}>
                      Create Event
                    </Button>
                  </Col>
                </FormGroup>
              </Container>
            )
          }

          return (
            <div className='my-3 my-md-5'>
              <div className='container'>
                <div className="loader"></div>
              </div>
            </div>
          )
        }}
      />
    )
  }
}

export { EventsNew }
