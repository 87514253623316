import graphql from 'babel-plugin-relay/macro'
import React, { Component } from 'react'
import { Col, Form, FormControl, FormGroup, FormLabel } from 'react-bootstrap'
import { commitMutation, createFragmentContainer } from 'react-relay'

import moment from 'moment'

import environment from '../Environment'
import Config from '../config'
const config = Config(process.env.REACT_APP_ENV)

const mutation = graphql`
  mutation EventsEditMutation($input: UpdateEventByIdInput!) {
    updateEventById(input: $input) {
      event {
        id
      }
    }
  }
`

const sanitize = ({ title, description, startsAt, endsAt }) => ({ title, description, startsAt, endsAt })

class Event extends Component {
  constructor (props) {
    super(props)

    this.state = {
      form: {
        ...sanitize(props.event)
      }
    }
  }

  saveChanges = () => {
    const variables = {
      input: {
        id: this.props.event.id,
        eventPatch: {
          ...this.state.form
        }
      }
    }

    return new Promise((resolve, reject) => {
      commitMutation(
        environment, {
          mutation,
          variables,
          onCompleted: _ => resolve(_),
          onError: err => reject(err)
        }
      )
    })
  }

  handleChange = event => {
    const { name, value } = event.target
    this.setState({ form: { ...this.state.form, [name]: value } })
  }

  render () {
    const { event, mode } = this.props
    if (event && !mode) {
      return (
        <div>
          <h5>Time</h5>
          <p>{moment(event.startsAt).format('MMM D, LT')} - {moment(event.endsAt).format('MMM D, LT')}</p>

          <h5>Title</h5>
          <p>{event.title}</p>

          <h5>Description</h5>
          <p style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: event.description }} />

          <h5>Location</h5>
          <img className='img-responsive' src={`https://maps.googleapis.com/maps/api/staticmap?zoom=13&size=640x300&scale=2&maptype=roadmap&markers=color:red%7Clabel:C%7C${event.latitude},${event.longitude}&key=${config.googleApiKey}`} alt={`${event.address || 'Unknown'} - ${event.latitude},${event.longitude}`} />
        </div>
      )
    } else {
      return (
        <Form horizontal>
          <FormGroup>
            <Col componentClass={FormLabel} sm={2}>
              Starts At
            </Col>
            <Col sm={4}>
              <FormControl name='startsAt' value={this.state.form.startsAt || ''} onChange={this.handleChange} />
            </Col>
            <Col componentClass={FormLabel} sm={2}>
              Ends At
            </Col>
            <Col sm={4}>
              <FormControl name='endsAt' value={this.state.form.endsAt || ''} onChange={this.handleChange} />
            </Col>
          </FormGroup>

          <FormGroup>
            <Col componentClass={FormLabel} sm={2}>
              Title
            </Col>
            <Col sm={10}>
              <FormControl name='title' placeholder='Title' value={this.state.form.title || ''} onChange={this.handleChange} />
            </Col>
          </FormGroup>

          <FormGroup>
            <Col componentClass={FormLabel} sm={2}>
              Description
            </Col>
            <Col sm={10}>
              <FormControl style={{ height: 200 }} name='description' componentClass='textarea' placeholder='Description' value={this.state.form.description || ''} onChange={this.handleChange} />
            </Col>
          </FormGroup>
        </Form>
      )
    }
  }
}

const EventContainer = createFragmentContainer(Event, {
  event: graphql`
    fragment Event_event on Event {
      id
      title
      description
      startsAt
      endsAt
      latitude
      longitude
      address
    }
  ` }
)

export { EventContainer as Event }
