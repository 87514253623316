import React, { Component } from 'react'
import { createFragmentContainer } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import moment from 'moment'

class GuestsList extends Component {
  renderAvatar = guest => {
    if (guest.user) {
      return <span className='avatar' style={{ backgroundImage: `url(${guest.user.pictureThumbnailUrl || guest.user.pictureUrl || `https://api.adorable.io/avatars/32/${guest.user.id}`})` }} />
    }

    return <span className='avatar' style={{ backgroundImage: `url(https://api.adorable.io/avatars/32/${guest.id})` }} />
  }

  renderName = guest => {
    if (guest.user) {
      return (
        <div>
          {guest.firstName || guest.user.firstName}<br />
          <small>{guest.lastName || guest.user.lastName}</small>
        </div>
      )
    }

    return (
      <div>
        {guest.firstName}<br />
        <small>{guest.lastName}</small>
      </div>
    )
  }

  renderGuestRow = guest => {
    return (
      <tr key={guest.id}>
        <td>{this.renderAvatar(guest)}</td>
        <td>{this.renderName(guest)}</td>
        <td><a href={`/web/i/${guest.id}`}>{guest.response}</a></td>
        <td>{moment(guest.updatedAt).format('MMM D, LT')}</td>
        <td>{guest.sent ? 'YES' : 'NO'}</td>
        <td><a href='/' className='icon' onClick={() => this.props.onDelete(guest.id)}><i className='fe fe-trash'></i></a></td>
      </tr>
    )
  }

  render () {
    const { guests } = this.props
    return (
      <table className='table'>
        <thead>
          <tr>
            <th width='1' />
            <th>Invitee</th>
            <th>Response</th>
            <th>Updated At</th>
            <th>Sent?</th>
            <th>-</th>
          </tr>
        </thead>
        <tbody>
          {guests.nodes.map(this.renderGuestRow)}
        </tbody>
      </table>
    )
  }
}

const GuestsListContainer = createFragmentContainer(GuestsList, {
  guests: graphql`
    fragment GuestsList_guests on GuestsConnection {
      nodes {
        id
        updatedAt
        firstName
        lastName
        user: userByUserId {
          id
          firstName
          lastName
          pictureUrl
          pictureThumbnailUrl
        }
        response
      }
    }
  ` }
)
export { GuestsListContainer as GuestsList }
