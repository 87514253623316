import graphql from 'babel-plugin-relay/macro'
import React, { Component } from 'react'
import { Form } from 'react-bootstrap'
import { createFragmentContainer } from 'react-relay'
import { Link } from 'react-router-dom'

class PartnerDestinations extends Component {
  constructor (props) {
    super(props)

    this.partnerId = props.partnerId

    this.state = {
      destinations: props.destinations
    }
  }

  renderDestination = ({ node: { id, name, description } }) => {
    return (
      <div key={id} className='col-12 mb-4'>
        <div className='row'>
          <div className='col-1'>
            <Link
              className='icon'
              to={`/destinations/${id}/edit`}
              onClick={e => {
                e.preventDefault()
                alert('not implemented')
              }}
            >
              <i className='fe fe-edit'></i>
            </Link>
          </div>
          <div className='col-11'>{name}</div>
        </div>
      </div>
    )
  }

  renderDestinations = destinations => {
    if (!destinations || destinations.edges.length === 0) {
      return <div />
    }

    return (
      <div id='destinations' className='row row-cards'>
        {destinations.edges.map(this.renderDestination)}
      </div>
    )
  }

  render () {
    const { destinations } = this.state

    return <Form horizontal>{this.renderDestinations(destinations)}</Form>
  }
}

const PartnerDestinationsContainer = createFragmentContainer(
  PartnerDestinations,
  {
    destinations: graphql`
      fragment PartnerDestinations_destinations on DestinationsConnection {
        edges {
          node {
            id
            name
            description
            latitude
            longitude
            address
          }
        }
      }
    `
  }
)

export { PartnerDestinationsContainer as PartnerDestinations }
