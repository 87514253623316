/**
 * @generated SignedSource<<4abc3cd4cf78dd19daef7f8e9ca326a6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "DeletePagePayload",
    "kind": "LinkedField",
    "name": "deletePageById",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Page",
        "kind": "LinkedField",
        "name": "page",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PageDeleteMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PageDeleteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4754bdba9e7f660d059ef376a964a254",
    "id": null,
    "metadata": {},
    "name": "PageDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation PageDeleteMutation(\n  $id: Uuid!\n) {\n  deletePageById(id: $id) {\n    page {\n      id\n    }\n  }\n}\n"
  }
};
})();

node.hash = "27f69c41c0690b81f32e36ac14b87be7";

module.exports = node;
