import graphql from 'babel-plugin-relay/macro'
import React, { Component } from 'react'
import { Form } from 'react-bootstrap'
import { createFragmentContainer } from 'react-relay'
import ReactS3Uploader from 'react-s3-uploader'
import shortid from 'shortid'

import { createPlacePhoto, deletePlacePhoto } from '../api'
import Config from '../config'
import { imageResizerUrl } from '../helpers'

const config = Config(process.env.REACT_APP_ENV)

class PlacePhotos extends Component {
  constructor (props) {
    super(props)

    this.placeId = props.placeId

    this.state = {
      error: '',
      photos: props.photos
    }
  }

  scrubFilename = filename => `${shortid.generate()}-${filename.replace(/[^\w\d_\-.]+/ig, '')}`

  onPlacePictureUploadFinished = async ({ url }) => {
    try {
      const { createPlacePhoto: { placePhoto: { id } } } = await createPlacePhoto({
        placeId: this.placeId,
        url,
        caption: '',
        description: ''
      })
      this.uploadInput.value = ''

      const patchedPhotos = this.state.photos.edges.slice()
      patchedPhotos.push({ node: { id, url } })

      this.setState({ photos: { edges: patchedPhotos }, error: '' })
    } catch (err) {
      this.setState({ error: err.message })
      console.error(`Failed to create place photo entity for url ${url}`)
    }
  }

  async deletePhoto (e) {
    e.preventDefault()

    const alert = window.confirm(`Are you sure you want to delete this photo?`)
    if (!!alert) {
      const { id } = e.currentTarget.dataset

      try {
        await deletePlacePhoto({ id })
        // TODO: filter photos based on deleted photo id
      } catch (err) {
        this.setState({ error: err.message })
        console.error(`Failed to delete place photo ${id}`)
      }
    }

    return false
  }

  renderPhoto = ({ node: { id, url, caption } }) => {
    const resizedUrl = imageResizerUrl(url, { width: 96, height: 72, fit: 'crop', dpr: 2 })

    return (
      <div key={id} className='col-md-6 col-lg-4 col-xl-3 mb-4'>
        <button key={id} data-id={id} onClick={this.deletePhoto.bind(this)} style={{ backgroundColor: 'transparent', border: 'none' }}><img src={resizedUrl} alt={caption || ''} style={{ width: 96, height: 72, borderRadius: 4 }} /></button>
      </div>
    )
  }

  renderPhotos = photos => {
    if (!photos || photos.edges.length === 0) {
      return (
        <div />
      )
    }

    return (
      <div id='photos' className='row row-cards'>
        {photos.edges.map(this.renderPhoto)}
      </div>
    )
  }

  render () {
    const { photos, error } = this.state

    return (
      <Form horizontal>
        {photos.edges.length < 4 ? <p><small>Consider adding at least four photos. Each photo must be a reasonable dimension i.e. should not be below at least 640x640</small></p> : null}
        {this.renderPhotos(photos)}
        <div style={{ padding: 8 }}>
          <div className='custom-file'>
            <ReactS3Uploader
              inputRef={ref => (this.uploadInput = ref)}
              className='custom-file-input'
              signingUrl={`${config.apiUrl}/sign-s3`}
              signingUrlMethod='GET'
              accept='image/*'
              onProgress={this.onUploadProgress}
              onError={this.onUploadError}
              onFinish={this.onPlacePictureUploadFinished}
              contentDisposition='auto'
              scrubFilename={this.scrubFilename}
            />
            <label className='custom-file-label'>Choose file</label>
          </div>
          {error}
        </div>
      </Form>
    )
  }
}

const PlacePhotosContainer = createFragmentContainer(PlacePhotos, {
  photos: graphql`
    fragment PlacePhotos_photos on PlacePhotosConnection {
      edges {
        node {
          id
          url
          caption
          description
        }
      }
    }
  `
})

export { PlacePhotosContainer as PlacePhotos }
