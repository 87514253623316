/**
 * @generated SignedSource<<f486e096b9cd7f5241909873bb509723>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "logoUrl",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "coverPhotoUrl",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "primaryColor",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "latitude",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "longitude",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PartnersEditQuery",
    "selections": [
      {
        "alias": "partner",
        "args": (v1/*: any*/),
        "concreteType": "Partner",
        "kind": "LinkedField",
        "name": "partnerById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Partner_partner"
          },
          {
            "alias": "photos",
            "args": null,
            "concreteType": "PartnerPhotosConnection",
            "kind": "LinkedField",
            "name": "partnerPhotosByPartnerId",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "PartnerPhotos_photos"
              }
            ],
            "storageKey": null
          },
          {
            "alias": "places",
            "args": null,
            "concreteType": "PlacesConnection",
            "kind": "LinkedField",
            "name": "placesByPartnerId",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "PartnerPlaces_places"
              }
            ],
            "storageKey": null
          },
          {
            "alias": "destinations",
            "args": null,
            "concreteType": "DestinationsConnection",
            "kind": "LinkedField",
            "name": "destinationsByPartnerId",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "PartnerDestinations_destinations"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PartnersEditQuery",
    "selections": [
      {
        "alias": "partner",
        "args": (v1/*: any*/),
        "concreteType": "Partner",
        "kind": "LinkedField",
        "name": "partnerById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "siteUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "secondaryColor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "bookingUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "facebookUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "twitterUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "instagramUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "youtubeUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "vimeoUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "linkedinUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phone",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "city",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "country",
            "storageKey": null
          },
          {
            "alias": "photos",
            "args": null,
            "concreteType": "PartnerPhotosConnection",
            "kind": "LinkedField",
            "name": "partnerPhotosByPartnerId",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PartnerPhotosEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PartnerPhoto",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "caption",
                        "storageKey": null
                      },
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": "places",
            "args": null,
            "concreteType": "PlacesConnection",
            "kind": "LinkedField",
            "name": "placesByPartnerId",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PlacesEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Place",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "radius",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": "destinations",
            "args": null,
            "concreteType": "DestinationsConnection",
            "kind": "LinkedField",
            "name": "destinationsByPartnerId",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "DestinationsEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Destination",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f849f3e15e88685444ee3f5d45d9d0b4",
    "id": null,
    "metadata": {},
    "name": "PartnersEditQuery",
    "operationKind": "query",
    "text": "query PartnersEditQuery(\n  $id: Uuid!\n) {\n  partner: partnerById(id: $id) {\n    id\n    logoUrl\n    coverPhotoUrl\n    primaryColor\n    slug\n    latitude\n    longitude\n    address\n    ...Partner_partner\n    photos: partnerPhotosByPartnerId {\n      totalCount\n      ...PartnerPhotos_photos\n    }\n    places: placesByPartnerId {\n      totalCount\n      ...PartnerPlaces_places\n    }\n    destinations: destinationsByPartnerId {\n      totalCount\n      ...PartnerDestinations_destinations\n    }\n  }\n}\n\nfragment PartnerDestinations_destinations on DestinationsConnection {\n  edges {\n    node {\n      id\n      name\n      description\n      latitude\n      longitude\n      address\n    }\n  }\n}\n\nfragment PartnerPhotos_photos on PartnerPhotosConnection {\n  edges {\n    node {\n      id\n      url\n      caption\n      description\n    }\n  }\n}\n\nfragment PartnerPlaces_places on PlacesConnection {\n  edges {\n    node {\n      id\n      name\n      description\n      latitude\n      longitude\n      address\n      radius\n    }\n  }\n}\n\nfragment Partner_partner on Partner {\n  id\n  name\n  description\n  logoUrl\n  coverPhotoUrl\n  siteUrl\n  primaryColor\n  secondaryColor\n  bookingUrl\n  facebookUrl\n  twitterUrl\n  instagramUrl\n  youtubeUrl\n  vimeoUrl\n  linkedinUrl\n  slug\n  phone\n  email\n  address\n  latitude\n  longitude\n  city\n  country\n}\n"
  }
};
})();

node.hash = "cebf3d86bfe8653600a1ded611b0e57b";

module.exports = node;
