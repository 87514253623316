import _ from 'lodash'

const slugify = string => _.toString(string).trim().toLowerCase()
  .replace(/ /g, '-')
  .replace(/æ/g, 'ae')
  .replace(/ø/g, 'oe')
  .replace(/å/g, 'aa')
  .replace(/([^a-zA-Z0-9_-])/g, '')

const theme = {
  styles: {
    header: {
      marginTop: 42,
      textTransform: 'uppercase',
      color: '#775E9A'
    },
    label: {
      textTransform: 'uppercase',
      fontSize: 'smaller',
      fontWeight: 'bold',
      color: '#999999',
      letterSpacing: 0,
      marginBottom: 0
    }
  }
}

const imageResizerUrl = (url, { width, height, fit, dpr } = {}) => {
  if (!url) return null

  const imageResizer = 'cloudimage'

  const basename = url.replace(/http?s:\/\/[^/]*\//, '')

  switch (imageResizer) {
    case 'imgix':
      return `https://${process.env.IMGIX_HOST || process.env.REACT_APP_IMGIX_HOST}/${basename}?${width ? `&w=${width}` : ''}${height ? `&h=${height}` : ''}${fit ? `&fit=${fit}` : ''}${dpr ? `&dpr=${dpr}` : ''}`
    case 'cloudimage':
      return `https://${process.env.CLOUDIMAGE_HOST || process.env.REACT_APP_CLOUDIMAGE_HOST}/${basename}?${width ? `&width=${width * (dpr || 1)}` : ''}${height ? `&height=${height * (dpr || 1)}` : ''}${(fit === 'crop') ? '&func=crop' : ''}&optipress=1`
    default:
      return null
  }
}

export {
  slugify,
  theme,
  imageResizerUrl
}
