/* eslint-disable jsx-a11y/no-access-key */
import graphql from 'babel-plugin-relay/macro'
import React, { useRef, useState } from 'react'
import { QueryRenderer } from 'react-relay'
import { useNavigate, useParams } from 'react-router-dom'

import environment from '../../Environment'
import { Error, Hub, HubPhotos, Loader } from '../../components'

import Config from '../../config'

const config = Config(process.env.REACT_APP_ENV)

const HubsEdit = () => {
  const [lastUpdatedAt, setLastUpdatedAt] = useState(null)
  const { id } = useParams()
  const navigate = useNavigate()
  const hubComponent = useRef()

  const handleSave = async () => {
    await hubComponent.current.saveChanges()
    setLastUpdatedAt(new Date())
    navigate(`/hubs/${id}/edit`)
  }

  const handleDelete = async () => {
    const alert = window.confirm(`Are you sure you want to delete this hub?`)
    if (alert === true) {
      await hubComponent.current.delete()
      navigate(`/hubs`)
    }
  }

  const renderMap = ({ address, latitude, longitude }) => {
    if (address && latitude && longitude) {
      return (
        <img
          className='img-responsive'
          src={`https://maps.googleapis.com/maps/api/staticmap?zoom=13&size=640x300&scale=2&maptype=roadmap&markers=color:red%7C${latitude},${longitude}&key=${config.googleApiKey}`}
          alt={`${address || 'Unknown'} - ${latitude},${longitude}`}
        />
      )
    }

    return <div>No Address</div>
  }

  return (
    <QueryRenderer
      environment={environment}
      query={graphql`
        query HubsEditQuery($id: Uuid!) {
          hub: hubById(id: $id) {
            id
            type
            slug
            latitude
            longitude
            address
            ...Hub_hub
            photos: hubPhotosByHubId {
              ...HubPhotos_photos
            }
          }
        }
      `}
      variables={{ id, lastUpdatedAt }}
      render={({ error, props }) => {
        const HubLinks = ({ hub: { type, slug } }) => {
          if (type === 'MUSEUM') {
            return (
              <div className='tags'>
                <span className='tag'>
                  Homepage{' '}
                  <a
                    href={`${config.siteUrl}/hubs/${slug}`}
                    className='tag-addon tag-blue'
                  >
                    <i className='fe fe-globe'></i>
                  </a>
                </span>
              </div>
            )
          } else if (type === 'SCHOOL') {
            return (
              <div className='tags'>
                <span className='tag'>
                  Homepage{' '}
                  <a
                    href={`${config.siteUrl}/hubs/${slug}`}
                    className='tag-addon tag-blue'
                  >
                    <i className='fe fe-globe'></i>
                  </a>
                </span>
                <span className='tag'>
                  ElevIntra{' '}
                  <a
                    href={`${config.siteUrl}/hubs/${slug}/elevintra`}
                    className='tag-addon tag-blue'
                  >
                    <i className='fe fe-globe'></i>
                  </a>
                </span>
                <span className='tag'>
                  ForældreIntra{' '}
                  <a
                    href={`${config.siteUrl}/hubs/${slug}/foraeldreintra`}
                    className='tag-addon tag-blue'
                  >
                    <i className='fe fe-globe'></i>
                  </a>
                </span>
                <span className='tag'>
                  LærerIntra{' '}
                  <a
                    href={`${config.siteUrl}/hubs/${slug}/laererintra`}
                    className='tag-addon tag-blue'
                  >
                    <i className='fe fe-globe'></i>
                  </a>
                </span>
                <span className='tag'>
                  SkoleIntra{' '}
                  <a
                    href={`${config.siteUrl}/hubs/${slug}/skoleintra`}
                    className='tag-addon tag-blue'
                  >
                    <i className='fe fe-globe'></i>
                  </a>
                </span>
                <span className='tag'>
                  Aula{' '}
                  <a
                    href={`${config.siteUrl}/hubs/${slug}/aula`}
                    className='tag-addon tag-blue'
                  >
                    <i className='fe fe-globe'></i>
                  </a>
                </span>
              </div>
            )
          } else {
            return (
              <div className='tags'>
                <span className='tag'>
                  Homepage{' '}
                  <a
                    href={`${config.siteUrl}/hubs/${slug}`}
                    className='tag-addon tag-blue'
                  >
                    <i className='fe fe-globe'></i>
                  </a>
                </span>
              </div>
            )
          }
        }

        if (error) return <Error error={error} />
        if (!props) return <Loader />

        return (
          <div className='my-3 my-md-5'>
            <div className='container'>
              <div className='row'>
                <div className='col-7'>
                  <div className='card'>
                    <div className='card-body'>
                      <Hub ref={hubComponent} hub={props.hub} mode='edit' />
                    </div>
                    <div className='card-footer text-right'>
                      <div className='d-flex'>
                        <button
                          className='btn btn-outline-danger mr-auto'
                          onClick={handleDelete}
                        >
                          Delete
                        </button>
                        <button
                          type='submit'
                          className='btn btn-primary ml-auto'
                          accessKey='S'
                          onClick={handleSave}
                        >
                          <u>S</u>ave changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-5'>
                  <div className='card'>
                    {renderMap(props.hub)}

                    <div className='card-body'>
                      <h3>Links</h3>
                      <HubLinks hub={props.hub} />
                    </div>
                  </div>

                  <div className='card'>
                    <div className='card-header'>Photos</div>
                    <div className='card-body'>
                      <HubPhotos
                        hubId={props.hub.id}
                        photos={props.hub.photos}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}

export { HubsEdit }
