import React, { Component } from 'react'
import { Container } from 'react-bootstrap'

import { AppsList } from '../../components'

class Apps extends Component {
  onCreate () {
    this.props.history.push('/apps/new')
  }

  componentDidMount () {}

  render () {
    return (
      <Container>
        <AppsList />
      </Container>
    )
  }
}

export { Apps }
