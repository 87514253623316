import graphql from 'babel-plugin-relay/macro'
import React from 'react'
import { QueryRenderer } from 'react-relay'
import { useNavigate } from 'react-router-dom'

import environment from '../../Environment'
import { Error, Loader, PartnersList } from '../../components'

const Partners = () => {
  const navigate = useNavigate()

  const handleCreate = () => navigate('/partners/new')

  return (
    <QueryRenderer
      environment={environment}
      query={graphql`
        query PartnersQuery {
          partners: allPartners(orderBy: NAME_ASC) {
            totalCount
            ...PartnersList_partners
            ...PartnersMap_partners
          }
        }
      `}
      variables={{}}
      render={({ error, props }) => {
        if (error) return <Error error={error} />
        if (!props) return <Loader />

        return (
          <div className='my-3 my-md-5'>
            {/* <PartnersMap partners={props.partners} /> */}

            <div className='container'>
              <div className='row row-cards'>
                <div className='col-12'>
                  <PartnersList
                    partners={props.partners}
                    onCreate={handleCreate}
                  />
                </div>
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}

export { Partners }
