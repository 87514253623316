/**
 * @generated SignedSource<<632da72e687c712d9703645e639c953b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": "photos",
  "args": null,
  "concreteType": "PlacePhotosConnection",
  "kind": "LinkedField",
  "name": "placePhotosByPlaceId",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PlacePhotosEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PlacePhoto",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PlacesEditQuery",
    "selections": [
      {
        "alias": "place",
        "args": (v1/*: any*/),
        "concreteType": "Place",
        "kind": "LinkedField",
        "name": "placeById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Place_place"
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PlacesEditQuery",
    "selections": [
      {
        "alias": "place",
        "args": (v1/*: any*/),
        "concreteType": "Place",
        "kind": "LinkedField",
        "name": "placeById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "address",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "latitude",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "longitude",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "radius",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ownerId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ownerType",
            "storageKey": null
          },
          {
            "alias": "partner",
            "args": null,
            "concreteType": "Partner",
            "kind": "LinkedField",
            "name": "partnerByPartnerId",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "792e5b5610fc15d46bbf47851a00323d",
    "id": null,
    "metadata": {},
    "name": "PlacesEditQuery",
    "operationKind": "query",
    "text": "query PlacesEditQuery(\n  $id: Uuid!\n) {\n  place: placeById(id: $id) {\n    id\n    ...Place_place\n    photos: placePhotosByPlaceId {\n      totalCount\n      edges {\n        node {\n          id\n          url\n        }\n      }\n    }\n  }\n}\n\nfragment Place_place on Place {\n  id\n  name\n  description\n  type\n  address\n  latitude\n  longitude\n  radius\n  ownerId\n  ownerType\n  partner: partnerByPartnerId {\n    id\n    name\n  }\n}\n"
  }
};
})();

node.hash = "03aab16b5af013b36fa10c4c1e054fa2";

module.exports = node;
