/**
 * @generated SignedSource<<971d0be5500d13a53ffdbf7906ccabd2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "types"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "types",
        "variableName": "types"
      }
    ],
    "kind": "ObjectValue",
    "name": "condition"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 64
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": "CREATED_AT_DESC"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HubsQuery",
    "selections": [
      {
        "alias": "hubs",
        "args": (v1/*: any*/),
        "concreteType": "HubsConnection",
        "kind": "LinkedField",
        "name": "allHubs",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "HubsList_hubs"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HubsQuery",
    "selections": [
      {
        "alias": "hubs",
        "args": (v1/*: any*/),
        "concreteType": "HubsConnection",
        "kind": "LinkedField",
        "name": "allHubs",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "HubsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Hub",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "address",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "phone",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "latitude",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "longitude",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f75db995f85b859b0b3f368c4c18ac65",
    "id": null,
    "metadata": {},
    "name": "HubsQuery",
    "operationKind": "query",
    "text": "query HubsQuery(\n  $types: [HubType]\n) {\n  hubs: allHubs(orderBy: CREATED_AT_DESC, first: 64, condition: {types: $types}) {\n    totalCount\n    ...HubsList_hubs\n  }\n}\n\nfragment HubsList_hubs on HubsConnection {\n  totalCount\n  edges {\n    node {\n      id\n      name\n      description\n      address\n      phone\n      email\n      type\n      latitude\n      longitude\n    }\n  }\n}\n"
  }
};
})();

node.hash = "1f8bcf07218bebe5338a1ede591dbfe6";

module.exports = node;
