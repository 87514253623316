/* eslint-disable jsx-a11y/no-access-key */
import graphql from 'babel-plugin-relay/macro'
import React, { useRef, useState } from 'react'
import { QueryRenderer } from 'react-relay'
import { useNavigate, useParams } from 'react-router-dom'
import { Card } from 'tabler-react'

import environment from '../../Environment'
import { Error, Loader, User } from '../../components'

import Config from '../../config'

const config = Config(process.env.REACT_APP_ENV)

const UsersEdit = () => {
  const [lastUpdatedAt, setLastUpdatedAt] = useState(null)
  const userComponent = useRef(null)
  const navigate = useNavigate()
  const { id } = useParams()

  const onSave = async () => {
    await userComponent.current.saveChanges()
    setLastUpdatedAt(new Date())
  }

  const onDelete = async () => {
    const alert = window.confirm(`Are you sure you want to delete this user?`)
    if (alert === true) {
      await userComponent.current.delete()
      navigate(`/users`)
    }
  }

  const renderMap = ({ lastAddress, lastLatitude, lastLongitude }) => {
    if (!lastLatitude && !lastLongitude) return null

    return (
      <Card>
        <Card.Body className='p-3 text-center'>
          <img
            className='img-responsive'
            src={`https://maps.googleapis.com/maps/api/staticmap?zoom=13&size=640x300&scale=2&maptype=roadmap&markers=color:red%7C${lastLatitude},${lastLongitude}&sensor=false&key=${config.googleApiKey}`}
            alt={`${
              lastAddress || 'Unknown'
            } - ${lastLatitude},${lastLongitude}`}
          />
        </Card.Body>
      </Card>
    )
  }

  return (
    <QueryRenderer
      environment={environment}
      query={graphql`
        query UsersEditQuery($id: Uuid!) {
          user: userById(id: $id) {
            id
            pictureUrl
            lastAddress
            lastLatitude
            lastLongitude
            ...User_user
          }
        }
      `}
      variables={{ id, lastUpdatedAt }}
      render={({ error, props }) => {
        if (error) return <Error error={error} />
        if (!props) return <Loader />

        return (
          <div className='my-3 my-md-5'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-8'>
                  <div className='card'>
                    <div className='card-body'>
                      <User ref={userComponent} user={props.user} />
                    </div>
                    <div className='card-footer text-right'>
                      <div className='d-flex'>
                        <button
                          className='btn btn-outline-danger mr-auto'
                          onClick={onDelete}
                        >
                          Delete
                        </button>
                        <button
                          type='submit'
                          className='btn btn-primary ml-auto'
                          accessKey='S'
                          onClick={onSave}
                        >
                          <u>S</u>ave changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-4'>
                  <Card>
                    <Card.Body className='p-3 text-center'>
                      <img
                        className='img-responsive'
                        src={props.user.pictureUrl}
                        alt=''
                      />
                    </Card.Body>
                  </Card>
                  {renderMap(props.user)}
                </div>
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}

export { UsersEdit }
