/**
 * @generated SignedSource<<e7c1eb20bb457a9d0bab6fbfbe895490>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "version",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PartnersNewQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PartnersNewQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "f1eacfed4633f488e342119ffdef6b44",
    "id": null,
    "metadata": {},
    "name": "PartnersNewQuery",
    "operationKind": "query",
    "text": "query PartnersNewQuery {\n  version\n}\n"
  }
};
})();

node.hash = "4d2bb146c4043afc0767e6e3221b1fd6";

module.exports = node;
