import React, { Component } from 'react'
import { QueryRenderer } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import { Button } from 'react-bootstrap'

import environment from '../../Environment'
import { Event, Error } from '../../components'

class EventsEdit extends Component {
  onSave = () => {
    this.eventComponent.saveChanges()
      .then(_ => {
        this.props.history.push(`/events/${this.props.match.params.id}`)
      })
      .catch(err => alert(err))
  }

  render () {
    return (
      <QueryRenderer
        environment={environment}
        query={graphql`
          query EventsEditQuery($id: Uuid!) {
            event: eventById(id: $id) {
              ...Event_event
            }
          }
        `}
        variables={{ id: this.props.match.params.id }}
        render={({error, props}) => {
          if (error) return <Error error={error} />

          if (props) {
            return (
              <div className='my-3 my-md-5'>
                <div className='container'>
                  <div className='row'>
                    <div className='col-lg-6'>
                      <div className='card'>
                        <div className='card-body'>
                          <Event ref={ref => (this.eventComponent = ref)} event={props.event} mode='edit' />
                        </div>
                        <div className='card-footer text-right'>
                          <Button bsStyle='success' onClick={this.onSave}>
                            Save
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-6'></div>
                  </div>
                </div>
              </div>
            )
          }

          return (
            <div className='my-3 my-md-5'>
              <div className='container'>
                <div className="loader"></div>
              </div>
            </div>
          )
        }}
      />
    )
  }
}

export { EventsEdit }
