import React from 'react'
import { CookiesProvider } from 'react-cookie'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'

import App from './App'
import * as serviceWorker from './serviceWorker'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <Router basename='/'>
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </Router>
)

serviceWorker.unregister()

// import './wdyr'

// import ReactDOM from 'react-dom/client'

// import { supportedLanguage } from '@familiohq/templ8-helpers'

// import App from './App'
// import Config from './Config'
// // import reportWebVitals from './reportWebVitals'

// const params = new URL(document.location).searchParams

// const SUPPORTED_LANGUAGES = ['en-US', 'da-DK']

// const language = params.get('language') || navigator.language || navigator.userLanguage
// const appLanguage = supportedLanguage(language, SUPPORTED_LANGUAGES)

// const { appName, environment } = Config(process.env.REACT_APP_ENV)

// const root = ReactDOM.createRoot(document.getElementById('root'))

// root.render(<App appName={appName} environment={environment} locale={appLanguage} />)

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// if (environment === 'development') {
//   // reportWebVitals(console.log)
// }
