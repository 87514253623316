import React, { Component } from 'react'
import { QueryRenderer } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import environment from '../../Environment'
import { Error, EventsList, EventsMap } from '../../components'

class Events extends Component {
  onCreateNewEvent = () => this.props.history.push('/events/new')

  render () {
    return (
      <QueryRenderer
        environment={environment}
        query={graphql`
          query EventsQuery {
            events: allEvents(orderBy: STARTS_AT_DESC) {
              totalCount
              ...EventsList_events
              ...EventsMap_events
            }
          }
        `}
        variables={{}}
        render={({ error, props }) => {
          if (error) return <Error error={error} />
          
          if (props) {
            return (
              <div className='my-3 my-md-5'>
                <EventsMap events={props.events} />

                <div className='container'>
                  <div className='row row-cards'>
                    <div className='col-lg-12'>
                      <EventsList events={props.events} />
                    </div>
                  </div>
                </div>
              </div>
            )
          }

          return (
            <div className='my-3 my-md-5'>
              <div className='container'>
                <div className="loader"></div>
              </div>
            </div>
          )
        }}
      />
    )
  }
}

export { Events }
