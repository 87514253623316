/* eslint-disable jsx-a11y/no-access-key */
import graphql from 'babel-plugin-relay/macro'
import React, { useRef, useState } from 'react'
import { QueryRenderer } from 'react-relay'
import { useNavigate, useParams } from 'react-router-dom'

import environment from '../../Environment'
import { Error, Loader, Page } from '../../components'

import Config from '../../config'

const config = Config(process.env.REACT_APP_ENV)

const PagesEdit = () => {
  const [lastUpdatedAt, setLastUpdatedAt] = useState(null)
  const navigate = useNavigate()
  const { id } = useParams()
  const pageComponent = useRef(null)

  const onSave = async () => {
    await pageComponent.current.saveChanges()
    setLastUpdatedAt(new Date())
    navigate(`/pages/${id}/edit`)
  }

  const onDelete = async () => {
    const alert = window.confirm(`Are you sure you want to delete this page?`)
    if (alert === true) {
      await pageComponent.current.delete()
      navigate(`/pages`)
    }
  }

  return (
    <QueryRenderer
      environment={environment}
      query={graphql`
        query PagesEditQuery($id: Uuid!) {
          page: pageById(id: $id) {
            id
            title
            path
            ...Page_page
          }
        }
      `}
      variables={{ id, lastUpdatedAt }}
      render={({ error, props }) => {
        if (error) return <Error error={error} />
        if (!props) return <Loader />

        const previewUrl = `${config.siteUrl}${props.page.path}`
        return (
          <div className='my-3 my-md-5'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-7'>
                  <div className='card'>
                    <div className='card-body'>
                      <Page ref={pageComponent} page={props.page} mode='edit' />
                    </div>
                    <div className='card-footer text-right'>
                      <div className='d-flex'>
                        <button
                          className='btn btn-outline-danger mr-auto'
                          onClick={onDelete}
                        >
                          Delete
                        </button>
                        <button
                          type='submit'
                          className='btn btn-primary ml-auto'
                          accessKey='S'
                          onClick={onSave}
                        >
                          <u>S</u>ave changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-5'>
                  <div className='card'>
                    <div className='card-header'>
                      <h3 className='card-title'>Preview</h3>
                      <div className='card-options'>
                        <a
                          href={previewUrl}
                          className='btn btn-primary btn-sm'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Open
                        </a>
                      </div>
                    </div>
                    <iframe
                      title={props.page.title}
                      src={previewUrl}
                      seamless='seamless'
                      frameBorder='0'
                      style={{ width: '100%', height: 480 }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}

export { PagesEdit }
