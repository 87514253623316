import graphql from 'babel-plugin-relay/macro'
import React, { useRef } from 'react'
import { Button, Col, FormGroup } from 'react-bootstrap'
import { QueryRenderer } from 'react-relay'
import { useNavigate } from 'react-router-dom'

import environment from '../../Environment'
import { Error, Loader, Partner } from '../../components'

const PartnersNew = () => {
  const navigate = useNavigate()
  const partnerComponent = useRef()

  const onCreate = async () => {
    const rsp = await partnerComponent.current.saveChanges()
    navigate(`/partners/${rsp.createPartner.partner.id}/edit`)
  }

  return (
    <QueryRenderer
      environment={environment}
      query={graphql`
        query PartnersNewQuery {
          version
        }
      `}
      render={({ error, props }) => {
        if (error) return <Error error={error} />
        if (!props) return <Loader />

        return (
          <div className='my-3 my-md-5'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='card'>
                    <div className='card-body'>
                      <Partner ref={partnerComponent} partner={null} />

                      <FormGroup>
                        <Col>
                          <Button
                            className='float-right'
                            bsStyle='success'
                            type='submit'
                            onClick={onCreate}
                          >
                            Create Partner
                          </Button>
                        </Col>
                      </FormGroup>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}

export { PartnersNew }
