/**
 * @generated SignedSource<<7a31d0da37a0c03dcef7d28e63990751>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "date",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AppStats",
    "kind": "LinkedField",
    "name": "appStats",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalUsers",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalUsersOneWeekAgo",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalUsersWithPushEnabled",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalIosUsers",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalAndroidUsers",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalFamilies",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalContacts",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalEvents",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalConversations",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalMessages",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalMessagesOneWeekAgo",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AverageMessagesByConversation",
        "kind": "LinkedField",
        "name": "averageMessagesByConversations",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "conversationType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "averageMessages",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "usersLastDay",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "usersLastWeek",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "usersLastMonth",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UsersCountByAuthenticationType",
        "kind": "LinkedField",
        "name": "usersLastMonthByAuthenticationType",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phone",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "facebook",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "google",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "apple",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "usersDeletedLastMonth",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "familiesWithPhones",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "familiesWithEmails",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "FamiliesMembersCount",
        "kind": "LinkedField",
        "name": "familiesMembersCount",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "membersCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "familiesCount",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UsersCountByDate",
        "kind": "LinkedField",
        "name": "usersCountByDay",
        "plural": true,
        "selections": (v0/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UsersCountByDate",
        "kind": "LinkedField",
        "name": "usersCountByWeek",
        "plural": true,
        "selections": (v0/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UsersCountByDate",
        "kind": "LinkedField",
        "name": "usersCountByMonth",
        "plural": true,
        "selections": (v0/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "MessagesCountByDate",
        "kind": "LinkedField",
        "name": "messagesCountByDay",
        "plural": true,
        "selections": (v0/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "MessagesCountByDate",
        "kind": "LinkedField",
        "name": "messagesCountByWeek",
        "plural": true,
        "selections": (v0/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "MessagesCountByDate",
        "kind": "LinkedField",
        "name": "messagesCountByMonth",
        "plural": true,
        "selections": (v0/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DashboardQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DashboardQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5fa08ad3b0ff8898d85e6a82ce7e8d6e",
    "id": null,
    "metadata": {},
    "name": "DashboardQuery",
    "operationKind": "query",
    "text": "query DashboardQuery {\n  appStats {\n    totalUsers\n    totalUsersOneWeekAgo\n    totalUsersWithPushEnabled\n    totalIosUsers\n    totalAndroidUsers\n    totalFamilies\n    totalContacts\n    totalEvents\n    totalConversations\n    totalMessages\n    totalMessagesOneWeekAgo\n    averageMessagesByConversations {\n      conversationType\n      averageMessages\n    }\n    usersLastDay\n    usersLastWeek\n    usersLastMonth\n    usersLastMonthByAuthenticationType {\n      phone\n      email\n      facebook\n      google\n      apple\n    }\n    usersDeletedLastMonth\n    familiesWithPhones\n    familiesWithEmails\n    familiesMembersCount {\n      membersCount\n      familiesCount\n    }\n    usersCountByDay {\n      date\n      count\n    }\n    usersCountByWeek {\n      date\n      count\n    }\n    usersCountByMonth {\n      date\n      count\n    }\n    messagesCountByDay {\n      date\n      count\n    }\n    messagesCountByWeek {\n      date\n      count\n    }\n    messagesCountByMonth {\n      date\n      count\n    }\n  }\n}\n"
  }
};
})();

node.hash = "b52e22b22839b3e175c0076dd40d1b2b";

module.exports = node;
