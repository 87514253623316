import graphql from 'babel-plugin-relay/macro'
import React, { useState } from 'react'
import { QueryRenderer } from 'react-relay'

import environment from '../../Environment'
import { Error, HubsList, Loader } from '../../components'

const Hubs = () => {
  const [types, setTypes] = useState([])

  const handleTypeFilter = types => setTypes(types)

  return (
    <QueryRenderer
      environment={environment}
      query={graphql`
        query HubsQuery($types: [HubType]) {
          hubs: allHubs(
            orderBy: CREATED_AT_DESC
            first: 64
            condition: { types: $types }
          ) {
            totalCount
            ...HubsList_hubs
          }
        }
      `}
      variables={{
        types: types.length > 0 ? types : null
      }}
      render={({ error, props }) => {
        if (error) return <Error error={error} />
        if (!props) return <Loader />

        const { hubs } = props

        return (
          <div className='my-3 my-md-5'>
            {/* <HubsMap partners={props.partners} /> */}

            <div className='container'>
              <div className='row row-cards'>
                <div className='col-lg-12'>
                  <HubsList
                    hubs={hubs || []}
                    types={types}
                    onTypeFilter={handleTypeFilter}
                  />
                </div>
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}

export { Hubs }
