/* eslint-disable jsx-a11y/no-access-key */
import graphql from 'babel-plugin-relay/macro'
import React, { useRef, useState } from 'react'
import { QueryRenderer } from 'react-relay'
import { useNavigate, useParams } from 'react-router-dom'

import environment from '../../Environment'
import { Error, Loader, Place, PlacePhotos } from '../../components'
import { theme } from '../../helpers'

const PlacesEdit = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [lastUpdatedAt, setLastUpdatedAt] = useState()
  const placeComponent = useRef()

  const handleSave = async () => {
    await placeComponent.currenct.saveChanges()
    setLastUpdatedAt(new Date())
    // this.props.history.push(`/partners/${this.props.match.params.id}/edit`)
  }

  const handleDelete = async () => {
    const alert = window.confirm(`Are you sure you want to delete this place?`)
    if (alert === true) {
      await placeComponent.current.delete()
      navigate('/places')
    }
  }

  return (
    <QueryRenderer
      environment={environment}
      query={graphql`
        query PlacesEditQuery($id: Uuid!) {
          place: placeById(id: $id) {
            id
            ...Place_place
            photos: placePhotosByPlaceId {
              totalCount
              edges {
                node {
                  id
                  url
                }
              }
            }
          }
        }
      `}
      variables={{ id, lastUpdatedAt }}
      render={({ error, props }) => {
        if (error) return <Error error={error} />
        if (!props) return <Loader />

        return (
          <div className='my-3 my-md-5'>
            <div className='container'>
              <div className='row'>
                <div className='col-7'>
                  <div className='card'>
                    <div className='card-body'>
                      <Place
                        ref={placeComponent}
                        place={props.place}
                        mode='edit'
                      />
                    </div>
                    <div className='card-footer text-right'>
                      <button
                        type='submit'
                        className='btn btn-primary ml-auto'
                        accessKey='S'
                        onClick={handleSave}
                      >
                        <u>S</u>ave
                      </button>
                      &nbsp;
                      <button
                        className='btn btn-outline-danger mr-auto'
                        onClick={handleDelete}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
                <div className='col-5'>
                  <div className='card'>
                    <div className='card-body'>
                      <h5 style={theme.styles.header}>
                        Photos ({props.place.photos.totalCount})
                      </h5>
                      <PlacePhotos
                        placeId={props.place.id}
                        photos={props.place.photos}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}

export { PlacesEdit }
