import graphql from 'babel-plugin-relay/macro'
import React, { Component } from 'react'
import { Col, Form, FormControl, FormGroup, FormLabel } from 'react-bootstrap'
import { commitMutation, createFragmentContainer } from 'react-relay'
import { Link } from 'react-router-dom'
import ReactS3Uploader from 'react-s3-uploader'
import shortid from 'shortid'

import environment from '../Environment'
import Config from '../config'
import { theme } from '../helpers'

const config = Config(process.env.REACT_APP_ENV)

const sanitize = ({ name, description, siteUrl, bookingUrl, primaryColor, secondaryColor, facebookUrl, twitterUrl, instagramUrl, youtubeUrl, vimeoUrl, linkedinUrl, slug, phone, email, logoUrl, coverPhotoUrl, address, latitude, longitude, city, country }) => ({ name, description, siteUrl, bookingUrl,primaryColor, secondaryColor, facebookUrl, twitterUrl, instagramUrl, youtubeUrl, vimeoUrl, linkedinUrl, slug, phone, email, logoUrl, coverPhotoUrl, address, latitude, longitude, city, country })

class Place extends Component {
  constructor (props) {
    super(props)

    this.state = {
      form: {
        ...sanitize(props.place || {})
      }
    }
  }

  create = () => {
    // const mutation = graphql`
    //   mutation PlaceCreateMutation($input: CreatePlaceInput!) {
    //     createPlace(input: $input) {
    //       place {
    //         id
    //       }
    //     }
    //   }
    // `

    // const variables = {
    //   input: {
    //     place: {
    //       ...this.state.form,
    //       slug: slugify(this.state.form.name)
    //     }
    //   }
    // }

    // return new Promise((resolve, reject) => {
    //   commitMutation(
    //     environment, {
    //       mutation,
    //       variables,
    //       onCompleted: (rsp, errors) => {
    //         if (errors) {
    //           console.log('failed', errors)
    //           reject(errors)
    //         } else {
    //           resolve(rsp)
    //         }
    //       },
    //       onError: err => {
    //         console.log('errored', err)
    //         reject(err)
    //       }
    //     }
    //   )
    // })
  }

  update = () => {
    // const mutation = graphql`
    //   mutation PlaceUpdateMutation($input: UpdatePlaceByIdInput!) {
    //     updatePlaceById(input: $input) {
    //       place {
    //         id
    //       }
    //     }
    //   }
    // `

    // const variables = {
    //   input: {
    //     id: this.props.place.id,
    //     placePatch: {
    //       ...this.state.form,
    //       latitude: this.state.form.latitude ? parseFloat(this.state.form.latitude) : null,
    //       longitude: this.state.form.longitude ? parseFloat(this.state.form.longitude) : null,
    //       slug: slugify(this.state.form.slug) // ensure we are not messing up slug on update
    //     }
    //   }
    // }

    // return new Promise((resolve, reject) => {
    //   commitMutation(
    //     environment, {
    //       mutation,
    //       variables,
    //       onCompleted: (rsp, errors) => {
    //         console.log(rsp, errors)
    //         if (errors) {
    //           console.log('failed', errors)
    //           reject(errors)
    //         } else {
    //           resolve(rsp)
    //         }
    //       },
    //       onError: err => {
    //         console.log('errored', err)
    //         reject(err)
    //       }
    //     }
    //   )
    // })
  }

  delete = () => {
    const mutation = graphql`
      mutation PlaceDeleteMutation($input: DeletePlaceByIdInput!) {
        deletePlaceById(input: $input) {
          place {
            id
          }
        }
      }
    `

    const variables = {
      input: {
        id: this.props.place.id
      }
    }

    return new Promise((resolve, reject) => {
      commitMutation(
        environment, {
          mutation,
          variables,
          onCompleted: (rsp, errors) => {
            if (errors) {
              console.log('failed', errors)
              reject(errors)
            } else {
              resolve(rsp)
            }
          },
          onError: err => {
            console.log('errored', err)
            reject(err)
          }
        }
      )
    })
  }

  isNew = () => (this.props.place === null || this.props.place.id === null)

  saveChanges = () => this.isNew()
    ? this.create()
    : this.update()

  onUploadProgress = rsp => console.log('got onUploadProgress()', rsp)

  onUploadError = rsp => console.log('got onUploadError()', rsp)

  onUploadFinish = rsp => this.setState({ form: { ...this.state.form, logoUrl: rsp.url } })

  scrubFilename = filename => `${shortid.generate()}-${filename.replace(/[^\w\d_\-.]+/ig, '')}`

  handleChange = place => {
    const { name, value } = place.target
    this.setState({ form: { ...this.state.form, [name]: value } })
  }

  renderField = (title, key, opts = {}, children = null) => {
    return (
      <FormGroup>
        <Col componentClass={FormLabel} style={theme.styles.label}>{title}</Col>
        <Col>
          <FormControl name={key} {...opts} placeholder={title} value={this.state.form[key] || ''} onChange={this.handleChange} />
        </Col>
        {children}
      </FormGroup>
    )
  }

  renderUploadField = (title, key) => {
    return (
      <FormGroup>
        <Col componentClass={FormLabel} style={theme.styles.label}>
          {title}
        </Col>
        <Col>
          <ReactS3Uploader
            signingUrl={`${config.apiUrl}/sign-s3`}
            signingUrlMethod='GET'
            accept='image/*'
            onProgress={this.onUploadProgress}
            onError={this.onUploadError}
            onFinish={rsp => this.setState({ form: { ...this.state.form, [key]: rsp.url } })}
            contentDisposition='auto'
            scrubFilename={this.scrubFilename} />
          <img className='img-responsive' width='96' src={this.state.form[key]} alt='' />
          {this.state.form[key] ? <button onClick={() => this.setState({ form: { ...this.state.form, [key]: null } })}>Remove</button> : null}
        </Col>
      </FormGroup>
    )
  }

  renderHeader = title => <h5 style={theme.styles.header}>{title}</h5>

  renderPartner = () => {
    const { place: { partner } } = this.props
    if (!partner) return null

    return (
      <FormGroup>
        <Col componentClass={FormLabel} style={theme.styles.label}>
          Partner
        </Col>
        <Col>
          <Link className='icon' to={`/partners/${partner.id}/edit`}><i className='fe fe-edit'></i> {partner.name}</Link>
        </Col>
      </FormGroup>
    )
  }

  render () {
    const { place, mode } = this.props
    if (place && !mode) {
      return (
        <div>
          hmm
        </div>
      )
    } else {
      return (
        <Form horizontal>
          {this.renderPartner()}
          {this.renderField('Name', 'name')}
          {this.renderField('Description', 'description', { style: { height: 200 }, componentClass: 'textarea'  })}

          <div className='form-group'>
            <label className="form-label" style={theme.styles.label}>Type</label>
            <select name='type' defaultValue={place.type} className="form-control custom-select selectized" onChange={this.handleChange}>
              {['HOME','WORK','SCHOOL','KINDERGARTEN','ACCOUNTING','AIRPORT','AMUSEMENT_PARK','AQUARIUM','ART_GALLERY','ATM','BAKERY','BANK','BAR','BEAUTY_SALON','BICYCLE_STORE','BOOK_STORE','BOWLING_ALLEY','BUS_STATION','CAFE','CAMPGROUND','CAR_DEALER','CAR_RENTAL','CAR_REPAIR','CAR_WASH','CASINO','CEMETERY','CHURCH','CITY_HALL','CLOTHING_STORE','CONVENIENCE_STORE','COURTHOUSE','DENTIST','DEPARTMENT_STORE','DOCTOR','ELECTRICIAN','ELECTRONICS_STORE','EMBASSY','FIRE_STATION','FLORIST','FUNERAL_HOME','FURNITURE_STORE','GAS_STATION','GYM','HAIR_CARE','HARDWARE_STORE','HINDU_TEMPLE','HOME_GOODS_STORE','HOSPITAL','INSURANCE_AGENCY','JEWELRY_STORE','LAUNDRY','LAWYER','LIBRARY','LIQUOR_STORE','LOCAL_GOVERNMENT_OFFICE','LOCKSMITH','LODGING','MEAL_DELIVERY','MEAL_TAKEAWAY','MOSQUE','MOVIE_RENTAL','MOVIE_THEATER','MOVING_COMPANY','MUSEUM','NIGHT_CLUB','PAINTER','PARK','PARKING','PET_STORE','PHARMACY','PHYSIOTHERAPIST','PLUMBER','POLICE','POST_OFFICE','REAL_ESTATE_AGENCY','RESTAURANT','ROOFING_CONTRACTOR','RV_PARK','SHOE_STORE','SHOPPING_MALL','SPA','STADIUM','STORAGE','STORE','SUBWAY_STATION','SUPERMARKET','SYNAGOGUE','TAXI_STAND','TRAIN_STATION','TRANSIT_STATION','TRAVEL_AGENCY','VETERINARY_CARE','ZOO','OTHER'].map(type => <option key={type}>{type}</option>)}
            </select>
          </div>

          {this.renderHeader('Address')}
          {this.renderField('Address', 'address')}
          {this.renderField('Latitude', 'latitude')}
          {this.renderField('Longitude', 'longitude')}
        </Form>
      )
    }
  }
}

const PlaceContainer = createFragmentContainer(Place, {
  place: graphql`
    fragment Place_place on Place {
      id
      name
      description
      type
      address
      latitude
      longitude
      radius
      ownerId
      ownerType
      partner: partnerByPartnerId {
        id
        name
      }
    }
  `
})

export { PlaceContainer as Place }
