import graphql from 'babel-plugin-relay/macro'
import { truncate } from 'lodash'
import React, { Component } from 'react'
import { createFragmentContainer } from 'react-relay'
import { Link } from 'react-router-dom'

class HubsList extends Component {
  state = {
    type: this.props.types?.length > 0 ? this.props.types[0] : ''
  }

  filtered = () => {
    if (!this.state.type) {
      return this.props.hubs.edges.map(e => e.node)
    }

    return this.props.hubs.edges
      .map(e => e.node)
      .filter(n => n.type === this.state.type)
  }

  onFilter = e => {
    e.preventDefault()
    this.props.onTypeFilter([e.currentTarget.dataset.type])
    // this.setState({ type: e.currentTarget.dataset.type })
  }

  renderRow = hub => {
    return (
      <tr key={hub.id}>
        <td>
          <Link to={`/hubs/${hub.id}/edit`}>{hub.name || 'Unnamed'}</Link>
          <br />
          <small>
            {truncate(hub.description, { length: 48 }) || (
              <span className='badge bg-orange-lt'>Missing description</span>
            )}
          </small>
        </td>
        <td className='d-none d-lg-table-cell'>{hub.address}</td>
        <td className='d-none d-lg-table-cell'>{hub.phone}</td>
        <td className='d-none d-lg-table-cell'>{hub.email}</td>
        <td className='d-none d-lg-table-cell'>{hub.type}</td>
      </tr>
    )
  }

  renderFilters = types => {
    return (
      <div className='dropdown'>
        <button
          type='button'
          className='btn btn-secondary dropdown-toggle'
          data-toggle='dropdown'
        >
          <i className='fe fe-filter mr-2'></i>Filter
        </button>
        <div className='dropdown-menu'>
          {[
            'AIRPORT',
            'AMUSEMENT_PARK',
            'AQUARIUM',
            'ARCADE',
            'ARCHIPELAGO',
            'ART_GALLERY',
            'ATM',
            'BAKERY',
            'BANK',
            'BAR',
            'BEAUTY_SALON',
            'BICYCLE_STORE',
            'BOOK_STORE',
            'BOWLING',
            'BOWLING_ALLEY',
            'BUS_STATION',
            'CAFE',
            'CAMPGROUND',
            'CAR_DEALER',
            'CAR_RENTAL',
            'CAR_REPAIR',
            'CAR_WASH',
            'CASINO',
            'CEMETERY',
            'CHURCH',
            'CITY_HALL',
            'CLOTHING_STORE',
            'COLLOQUIAL_AREA',
            'CONTINENT',
            'CONVENIENCE_STORE',
            'COUNTRY',
            'COURTHOUSE',
            'DENTIST',
            'DEPARTMENT_STORE',
            'DOCTOR',
            'DRUGSTORE',
            'ELECTRICIAN',
            'ELECTRONICS_STORE',
            'EMBASSY',
            'ENTERTAINMENT',
            'ESTABLISHMENT',
            'FINANCE',
            'FIRE_STATION',
            'FLOOR',
            'FLORIST',
            'FOOD',
            'FUNERAL_HOME',
            'FURNITURE_STORE',
            'GAS_STATION',
            'GENERAL_CONTRACTOR',
            'GEOCODE',
            'GROCERY_OR_SUPERMARKET',
            'GYM',
            'HAIR_CARE',
            'HARDWARE_STORE',
            'HEALTH',
            'HINDU_TEMPLE',
            'HOME_GOODS_STORE',
            'HOSPITAL',
            'INSURANCE_AGENCY',
            'INTERSECTION',
            'JEWELRY_STORE',
            'KID_CLASS',
            'KID_GYM',
            'LAUNDRY',
            'LAWYER',
            'LIBRARY',
            'LIGHT_RAIL_STATION',
            'LIQUOR_STORE',
            'LOCAL_GOVERNMENT_OFFICE',
            'LOCALITY',
            'LOCKSMITH',
            'LODGING',
            'MEAL_DELIVERY',
            'MEAL_TAKEAWAY',
            'MOSQUE',
            'MOVIE_RENTAL',
            'MOVIE_THEATER',
            'MOVING_COMPANY',
            'MUSEUM',
            'NATURAL_FEATURE',
            'NEIGHBORHOOD',
            'NIGHT_CLUB',
            'OTHER',
            'PAINTER',
            'PARK',
            'PARKING',
            'PET_STORE',
            'PHARMACY',
            'PHYSIOTHERAPIST',
            'PLACE_OF_WORSHIP',
            'PLAYGROUND',
            'PLUMBER',
            'PLUS_CODE',
            'POINT_OF_INTEREST',
            'POLICE',
            'POLITICAL',
            'POST_BOX',
            'POST_OFFICE',
            'POSTAL_CODE',
            'POSTAL_CODE_PREFIX',
            'POSTAL_CODE_SUFFIX',
            'POSTAL_TOWN',
            'PREMISE',
            'PRIMARY_SCHOOL',
            'REAL_ESTATE_AGENCY',
            'RESTAURANT',
            'ROOFING_CONTRACTOR',
            'ROOM',
            'ROUTE',
            'RV_PARK',
            'SCHOOL',
            'SECONDARY_SCHOOL',
            'SHOE_STORE',
            'SHOPPING_MALL',
            'SPA',
            'SPIRITUAL_CENTER',
            'STADIUM',
            'STORAGE',
            'STORE',
            'STREET_ADDRESS',
            'STREET_NUMBER',
            'SUBLOCALITY',
            'SUBPREMISE',
            'SUBWAY_STATION',
            'SUPERMARKET',
            'SYNAGOGUE',
            'TAXI_STAND',
            'THEME_PARK',
            'TOURIST_ATTRACTION',
            'TOWN_SQUARE',
            'TRAIN_STATION',
            'TRANSIT_STATION',
            'TRAVEL_AGENCY',
            'UNIVERSITY',
            'VETERINARY_CARE',
            'ZOO'
          ].map(type => (
            <a
              key={type}
              data-type={type || ''}
              href='/'
              className='dropdown-item'
              onClick={this.onFilter}
            >
              {type || 'Any'}
            </a>
          ))}
        </div>
      </div>
    )
  }

  render () {
    const types = [
      '',
      ...[...new Set(this.props.hubs.edges.map(({ node }) => node.type))]
    ].sort()
    const hubs = this.filtered()

    return (
      <div className='card'>
        <div className='card-header'>
          <h3 className='card-title'>{`Hubs ${this.state.type} (${hubs.length} / ${this.props.hubs.totalCount})`}</h3>
          <div style={{ marginLeft: 'auto', marginRight: '-1em' }}>
            {this.renderFilters(types)}
          </div>
        </div>
        <div className='table-responsive'>
          <table className='table card-table table-striped table-vcenter'>
            <thead>
              <tr>
                <th>Name</th>
                <th className='d-none d-lg-table-cell'>Address</th>
                <th className='d-none d-lg-table-cell'>Phone</th>
                <th className='d-none d-lg-table-cell'>Email</th>
                <th className='d-none d-lg-table-cell'>Type</th>
              </tr>
            </thead>
            <tbody>{hubs.map(this.renderRow)}</tbody>
          </table>
        </div>
      </div>
    )
  }
}

const HubsListContainer = createFragmentContainer(HubsList, {
  hubs: graphql`
    fragment HubsList_hubs on HubsConnection {
      totalCount
      edges {
        node {
          id
          name
          description
          address
          phone
          email
          type
          latitude
          longitude
        }
      }
    }
  `
})
export { HubsListContainer as HubsList }
