import React, { Component } from 'react'
import { createFragmentContainer } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import { Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'

class PartnerPlaces extends Component {
  constructor (props) {
    super(props)

    this.partnerId = props.partnerId

    this.state = {
      places: props.places
    }
  }

  renderPlace = ({ node: { id, name, description } }) => {
    return (
      <div key={id} className='col-12 mb-4'>
        <div className='row'>
          <div className='col-1'>
            <Link className='icon' to={`/places/${id}/edit`}><i className='fe fe-edit'></i></Link>
          </div>
          <div className='col-11'>
            {name}
          </div>
        </div>
      </div>
    )
  }

  renderPlaces = places => {
    if (!places || places.edges.length === 0) {
      return (
        <div />
      )
    }

    return (
      <div id='places' className='row row-cards'>
        {places.edges.map(this.renderPlace)}
      </div>
    )
  }

  render () {
    const { places } = this.state

    return (
      <Form horizontal>
        {this.renderPlaces(places)}
      </Form>
    )
  }
}

const PartnerPlacesContainer = createFragmentContainer(PartnerPlaces, {
  places: graphql`
    fragment PartnerPlaces_places on PlacesConnection {
      edges {
        node {
          id
          name
          description
          latitude
          longitude
          address
          radius
        }
      }
    }
  ` }
)

export { PartnerPlacesContainer as PartnerPlaces }
