/* eslint-disable jsx-a11y/no-access-key */
import graphql from 'babel-plugin-relay/macro'
import React, { useRef, useState } from 'react'
import { QueryRenderer } from 'react-relay'
import { useNavigate, useParams } from 'react-router-dom'

import environment from '../../Environment'
import {
  Error,
  Loader,
  Partner,
  PartnerDestinations,
  PartnerPhotos,
  PartnerPlaces
} from '../../components'
import { theme } from '../../helpers'

import Config from '../../config'

const config = Config(process.env.REACT_APP_ENV)

const PartnersEdit = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [lastUpdatedAt, setLastUpdatedAt] = useState()
  const partnerComponent = useRef()

  const handleSave = async () => {
    await partnerComponent.current.saveChanges()
    setLastUpdatedAt(new Date())
    navigate(`/partners/${id}/edit`)
  }

  const handleDelete = async () => {
    const alert = window.confirm(
      `Are you sure you want to delete this partner?`
    )
    if (!!alert) {
      await partnerComponent.current.delete()
      navigate(`/partners`)
    }
  }

  const renderLogo = ({ logoUrl }) => {
    if (logoUrl) {
      return (
        <center>
          <img className='img-responsive' src={logoUrl} alt='' />
        </center>
      )
    }

    return <div>No Logo</div>
  }

  const renderCoverPhoto = ({ coverPhotoUrl }) => {
    if (!coverPhotoUrl) return <div>No Cover Photo</div>

    return (
      <center>
        <img className='img-responsive' src={coverPhotoUrl} alt='' />
      </center>
    )
  }

  const renderMap = ({ address, latitude, longitude }) => {
    if (address && latitude && longitude) {
      return (
        <img
          className='img-responsive'
          src={`https://maps.googleapis.com/maps/api/staticmap?zoom=13&size=640x300&scale=2&maptype=roadmap&markers=color:red%7C${latitude},${longitude}&key=${config.googleApiKey}`}
          alt={`${address || 'Unknown'} - ${latitude},${longitude}`}
        />
      )
    }

    return <div>{address || 'No Address'}</div>
  }

  const renderPartnerLandingPage = ({ id, slug }) => {
    return (
      <div>
        <h4>Landing Page</h4>
        <p>
          <a
            href={`${config.siteUrl}/partners/${slug || id}`}
            target='_blank'
            rel='noopener noreferrer'
          >{`${config.siteUrl}/partners/${slug || id}`}</a>
        </p>
      </div>
    )
  }

  const renderPartnerLink = ({ id, slug }) => {
    return (
      <div>
        <h4>Download Link</h4>
        <p>
          <a
            href={`${config.siteUrl}/partners/${slug || id}/download`}
            target='_blank'
            rel='noopener noreferrer'
          >{`${config.siteUrl}/partners/${slug || id}/download`}</a>
        </p>
        <p>
          Include "?appName=NAME" for specific configuration such as
          "?appName=outboundhq" for Outbound specific landing pages.
        </p>
      </div>
    )
  }

  const renderWidgetCode = ({ id, slug }) => {
    return (
      <div>
        <h4>Widget</h4>
        <code
          dangerouslySetInnerHTML={{
            __html: `&ltiframe width="100%" height="500" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="${
              config.siteUrl
            }/widgets/download/${slug || id}">&lt;/iframe>`
          }}
        />
      </div>
    )
  }

  return (
    <QueryRenderer
      environment={environment}
      query={graphql`
        query PartnersEditQuery($id: Uuid!) {
          partner: partnerById(id: $id) {
            id
            logoUrl
            coverPhotoUrl
            primaryColor
            slug
            latitude
            longitude
            address
            ...Partner_partner
            photos: partnerPhotosByPartnerId {
              totalCount
              ...PartnerPhotos_photos
            }
            places: placesByPartnerId {
              totalCount
              ...PartnerPlaces_places
            }
            destinations: destinationsByPartnerId {
              totalCount
              ...PartnerDestinations_destinations
            }
          }
        }
      `}
      variables={{ id, lastUpdatedAt }}
      render={({ error, props }) => {
        if (error) return <Error error={error} />
        if (!props) return <Loader />
        if (!props.partner) return <div>partner is null</div>

        return (
          <div className='my-3 my-md-5'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-7'>
                  <div className='card'>
                    <div className='card-body'>
                      <Partner
                        ref={partnerComponent}
                        partner={props.partner}
                        mode='edit'
                      />
                    </div>
                    <div className='card-footer text-right'>
                      <div className='d-flex'>
                        <button
                          className='btn btn-outline-danger mr-auto'
                          onClick={handleDelete}
                        >
                          Delete
                        </button>
                        <button
                          type='submit'
                          className='btn btn-primary ml-auto'
                          accessKey='S'
                          onClick={handleSave}
                        >
                          <u>S</u>ave changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-5'>
                  <div className='card'>
                    <div className='card-body'>
                      {renderLogo(props.partner)}

                      <h5 style={theme.styles.header}>Cover Photo</h5>
                      {renderCoverPhoto(props.partner)}

                      <h5 style={theme.styles.header}>Map</h5>
                      {renderMap(props.partner)}

                      <h5 style={theme.styles.header}>
                        Photos ({props.partner.photos.totalCount})
                      </h5>
                      <PartnerPhotos
                        partnerId={props.partner.id}
                        photos={props.partner.photos}
                      />

                      <h5 style={theme.styles.header}>
                        Places ({props.partner.places.totalCount})
                      </h5>
                      <PartnerPlaces
                        partnerId={props.partner.id}
                        places={props.partner.places}
                      />

                      <h5 style={theme.styles.header}>
                        Destinations ({props.partner.destinations.totalCount})
                      </h5>
                      <PartnerDestinations
                        partnerId={props.partner.id}
                        destinations={props.partner.destinations}
                      />

                      <hr />

                      {renderPartnerLandingPage(props.partner)}

                      {renderPartnerLink(props.partner)}

                      {renderWidgetCode(props.partner)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}

export { PartnersEdit }
