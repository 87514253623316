import graphql from 'babel-plugin-relay/macro'
import React, { useRef } from 'react'
import { Button, FormGroup } from 'react-bootstrap'
import { QueryRenderer } from 'react-relay'
import { useNavigate } from 'react-router-dom'

import environment from '../../Environment'
import { Error, Loader, Page } from '../../components'

const PagesNew = () => {
  const navigate = useNavigate()
  const pageComponent = useRef(null)

  const onCreate = async () => {
    const rsp = await pageComponent.current.saveChanges()
    navigate(`/pages/${rsp.createPage.page.id}/edit`)
  }

  return (
    <QueryRenderer
      environment={environment}
      query={graphql`
        query PagesNewQuery {
          version
        }
      `}
      render={({ error, props }) => {
        if (error) return <Error error={error} />
        if (!props) return <Loader />

        return (
          <div className='my-3 my-md-5'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='card'>
                    <div className='card-body'>
                      <Page
                        ref={pageComponent}
                        page={null}
                        mode='new'
                        // hardcoded to the familio partner for now
                        partnerId={'84e4519a-7d05-11ea-9a4c-a72936c87722'}
                      />
                    </div>
                    <div className='card-footer text-right'>
                      <FormGroup>
                        <Button
                          className='float-right'
                          bsStyle='success'
                          type='submit'
                          onClick={onCreate}
                        >
                          Create Page
                        </Button>
                      </FormGroup>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}

export { PagesNew }
