import graphql from 'babel-plugin-relay/macro'
import _ from 'lodash'
import React from 'react'
import { QueryRenderer } from 'react-relay'
import { Link, useNavigate } from 'react-router-dom'

import environment from '../../Environment'
import { Error, Loader } from '../../components'

const Pages = () => {
  const navigate = useNavigate()

  const handleCreate = () => navigate('/pages/new')

  const renderRow = ({
    id,
    title,
    description,
    keywords,
    path,
    language,
    ownerId,
    partner,
    createdAt
  }) => {
    return (
      <tr key={id}>
        <td>
          <Link to={`/pages/${id}/edit`}>{title || 'Unnamed'}</Link>
          <br />
          <small>
            {_.truncate(description, { length: 64 }) || (
              <span className='badge bg-orange-lt'>Missing description</span>
            )}
          </small>
        </td>
        <td className='d-none d-lg-table-cell'>
          {path}
          <br />
          <small>
            {_.truncate(keywords, { length: 64 }) || (
              <span className='badge bg-orange-lt'>Missing keywords</span>
            )}
          </small>
        </td>
        <td className='d-none d-lg-table-cell'>{language}</td>
        <td className='d-none d-lg-table-cell'>{partner?.name}</td>
        <td className='d-none d-lg-table-cell'>{createdAt}</td>
      </tr>
    )
  }

  return (
    <QueryRenderer
      environment={environment}
      query={graphql`
        query PagesQuery {
          pages: allPages {
            totalCount
            edges {
              page: node {
                id
                ownerId
                ownerType
                partner: partnerByPartnerId {
                  id
                  name
                }
                createdAt
                language
                path
                title
                keywords
                description
              }
            }
          }
        }
      `}
      render={({ error, props }) => {
        if (error) return <Error error={error} />
        if (!props) return <Loader />

        const sortedPages = _.reverse(
          _.sortBy(
            props.pages.edges
              .filter(e => e.page.ownerType === 'PARTNER')
              .map(e => e.page),
            'createdAt'
          )
        )

        return (
          <div className='my-3 my-md-5'>
            <div className='container'>
              <div className='row row-cards'>
                <div className='col-lg-12'>
                  <div className='card'>
                    <div className='card-header'>
                      <h3 className='card-title'>
                        Pages ({props.pages.totalCount})
                      </h3>
                      <div style={{ marginLeft: 'auto', marginRight: '-1em' }}>
                        <button
                          className='btn btn-success ml-4'
                          onClick={handleCreate}
                        >
                          Create New
                        </button>
                      </div>
                    </div>
                    <div className='table-responsive'>
                      <table className='table card-table table-striped table-vcenter'>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th className='d-none d-lg-table-cell'>Path</th>
                            <th className='d-none d-lg-table-cell'>Language</th>
                            <th className='d-none d-lg-table-cell'>Partner</th>
                            <th className='d-none d-lg-table-cell'>Created</th>
                          </tr>
                        </thead>
                        <tbody>{sortedPages.map(renderRow)}</tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}

export { Pages }
