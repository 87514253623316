/**
 * @generated SignedSource<<a7ee0be7966d49c09ce2a24b74230911>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "page",
        "variableName": "input"
      }
    ],
    "concreteType": "CreatePagePayload",
    "kind": "LinkedField",
    "name": "createPage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Page",
        "kind": "LinkedField",
        "name": "page",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PageCreateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PageCreateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3ae2d18c1389f3940bef2ba47fce3357",
    "id": null,
    "metadata": {},
    "name": "PageCreateMutation",
    "operationKind": "mutation",
    "text": "mutation PageCreateMutation(\n  $input: PageInput!\n) {\n  createPage(page: $input) {\n    page {\n      id\n    }\n  }\n}\n"
  }
};
})();

node.hash = "4b0a21e78717be22a71abd9f55cc633a";

module.exports = node;
