import graphql from 'babel-plugin-relay/macro'
import _ from 'lodash'
import React, { Component } from 'react'
import { createFragmentContainer } from 'react-relay'
import { Link } from 'react-router-dom'

class PartnersList extends Component {
  state = {
    country: '',
    type: ''
  }

  filtered = () => {
    let filteredPartners = this.props.partners.edges
    if (this.state.country) {
      filteredPartners = filteredPartners.filter(edge => edge.node.country === this.state.country)
    } else if (this.state.type) {
      filteredPartners = filteredPartners.filter(edge => edge.node.type === this.state.type)
    }
    return filteredPartners.map(e => e.node)
  }

  filterByCountry = e => {
    e.preventDefault()
    this.setState({ country: e.currentTarget.dataset.country })
  }

  filterByType = e => {
    e.preventDefault()
    this.setState({ type: e.currentTarget.dataset.type })
  }

  renderRow = partner => {
    return (
      <tr key={partner.id}>
        <td>
           <Link to={`/partners/${partner.id}/edit`}>{partner.name || 'Unnamed'}</Link><br />
           <small>{_.truncate(partner.description, { length: 64 }) || <span className="badge bg-orange-lt">Missing description</span>}</small>
        </td>
        <td className="d-none d-lg-table-cell">{partner.phone}</td>
        <td className="d-none d-lg-table-cell">{partner.email}</td>
        <td className="d-none d-lg-table-cell"><span className={partner.logoUrl ? 'badge badge-secondary' : 'badge badge-danger'}>{partner.logoUrl ? 'Yes' : 'No'}</span></td>
      </tr>
    )
  }

  renderFilterByType = types => {
    const { type: selectedType } = this.state

    return (
      <div className="dropdown ml-2">
        <button type="button" className="btn btn-secondary dropdown-toggle" data-toggle="dropdown">
          <i className="fe fe-filter mr-2"></i>{selectedType ? `${selectedType}` : 'Types'}
        </button>
        <div className="dropdown-menu">
          {['', ...types].map(type => <a key={type} data-type={type || ''} href='/' className="dropdown-item" onClick={this.filterByType}>{type || 'Any'}</a>)}
        </div>
      </div>
    )
  }

  renderFilterByCountry = countries => {
    const { country: selectedCountry } = this.state

    return (
      <div className="dropdown ml-2">
        <button type="button" className="btn btn-secondary dropdown-toggle" data-toggle="dropdown">
          <i className="fe fe-filter mr-2"></i>{selectedCountry ? `${selectedCountry}` : 'Countries'}
        </button>
        <div className="dropdown-menu">
          {['', ...countries].map(country => <a key={country} data-country={country || ''} href='/' className="dropdown-item" onClick={this.filterByCountry}>{country || 'Any'}</a>)}
        </div>
      </div>
    )
  }

  render () {
    const countries = [...new Set(this.props.partners.edges.map(edge => edge.node.country).filter(Boolean))].sort()
    const types = [...new Set(this.props.partners.edges.map(edge => edge.node.type).filter(Boolean))].sort()

    const partners = this.filtered()

    return (
      <div className='card'>
        <div className='card-header'>
          <h3 className='card-title'>{`Partners (${partners.length})`}</h3>
          <div style={{ marginLeft: 'auto', marginRight: '-1em' }}>
            {this.renderFilterByCountry(countries)}
            {this.renderFilterByType(types)}
            <button className='btn btn-success ml-4' onClick={this.props.onCreate}>Create New</button>
          </div>
        </div>
        <div className='table-responsive'>
          <table className='table card-table table-striped table-vcenter'>
            <thead>
              <tr>
                <th>Name</th>
                <th className="d-none d-lg-table-cell">Phone</th>
                <th className="d-none d-lg-table-cell">Email</th>
                <th className="d-none d-lg-table-cell">Logo</th>
              </tr>
            </thead>
            <tbody>
              {partners.map(this.renderRow)}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

const PartnersListContainer = createFragmentContainer(PartnersList, {
  partners: graphql`
    fragment PartnersList_partners on PartnersConnection {
      totalCount
      edges {
        node {
          id
          name
          description
          phone
          email
          latitude
          longitude
          country
          logoUrl
          siteUrl
          type
        }
      }
    }
  ` }
)
export { PartnersListContainer as PartnersList }
