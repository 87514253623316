/**
 * @generated SignedSource<<4707ae913786709141955e5af60fdbe8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "version",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PagesNewQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PagesNewQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "9bd0964e4f68c2b4325b9b61aab655ab",
    "id": null,
    "metadata": {},
    "name": "PagesNewQuery",
    "operationKind": "query",
    "text": "query PagesNewQuery {\n  version\n}\n"
  }
};
})();

node.hash = "c1f5cb3a50b8a756153db19fdae1867e";

module.exports = node;
