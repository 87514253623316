/**
 * @generated SignedSource<<853bf3055f7741df8fe998ede55305c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "latitude",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "longitude",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HubsEditQuery",
    "selections": [
      {
        "alias": "hub",
        "args": (v1/*: any*/),
        "concreteType": "Hub",
        "kind": "LinkedField",
        "name": "hubById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Hub_hub"
          },
          {
            "alias": "photos",
            "args": null,
            "concreteType": "HubPhotosConnection",
            "kind": "LinkedField",
            "name": "hubPhotosByHubId",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "HubPhotos_photos"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HubsEditQuery",
    "selections": [
      {
        "alias": "hub",
        "args": (v1/*: any*/),
        "concreteType": "Hub",
        "kind": "LinkedField",
        "name": "hubById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phone",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "country",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "facebookUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "youtubeUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "twitterUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "instagramUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "linkedinUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "vimeoUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "siteUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mobileSiteUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "iosAppUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "androidAppUrl",
            "storageKey": null
          },
          {
            "alias": "photos",
            "args": null,
            "concreteType": "HubPhotosConnection",
            "kind": "LinkedField",
            "name": "hubPhotosByHubId",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "HubPhotosEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "HubPhoto",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "caption",
                        "storageKey": null
                      },
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1ec80ed55bfb6003d4cdb599551c8de8",
    "id": null,
    "metadata": {},
    "name": "HubsEditQuery",
    "operationKind": "query",
    "text": "query HubsEditQuery(\n  $id: Uuid!\n) {\n  hub: hubById(id: $id) {\n    id\n    type\n    slug\n    latitude\n    longitude\n    address\n    ...Hub_hub\n    photos: hubPhotosByHubId {\n      ...HubPhotos_photos\n    }\n  }\n}\n\nfragment HubPhotos_photos on HubPhotosConnection {\n  edges {\n    node {\n      id\n      url\n      caption\n      description\n    }\n  }\n}\n\nfragment Hub_hub on Hub {\n  id\n  name\n  description\n  slug\n  phone\n  email\n  type\n  address\n  latitude\n  longitude\n  country\n  facebookUrl\n  youtubeUrl\n  twitterUrl\n  instagramUrl\n  linkedinUrl\n  vimeoUrl\n  siteUrl\n  mobileSiteUrl\n  iosAppUrl\n  androidAppUrl\n}\n"
  }
};
})();

node.hash = "c103db77022d831229c88d08042973a8";

module.exports = node;
