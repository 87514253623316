import graphql from 'babel-plugin-relay/macro'
// import { Configuration, OpenAIApi } from 'openai'
import React, { Component } from 'react'
import { Form } from 'react-bootstrap'
import { commitMutation, createFragmentContainer } from 'react-relay'
import ReactS3Uploader from 'react-s3-uploader'
import shortid from 'shortid'

import environment from '../Environment'
import Config from '../config'
import { imageResizerUrl } from '../helpers'

const config = Config(process.env.REACT_APP_ENV)

// const configuration = new Configuration({
//   apiKey: process.env.REACT_APP_OPENAI_API_KEY
// })

// const openai = new OpenAIApi(configuration)

class HubPhotos extends Component {
  state = {
    photos: this.props.photos
  }

  scrubFilename = filename =>
    `${shortid.generate()}-${filename.replace(/[^\w\d_\-.]+/gi, '')}`

  create = ({ hubId, url, caption, description }) => {
    const mutation = graphql`
      mutation HubPhotosCreateMutation($input: CreateHubPhotoInput!) {
        createHubPhoto(input: $input) {
          hubPhoto {
            id
          }
        }
      }
    `

    const variables = {
      input: {
        hubPhoto: {
          hubId,
          url,
          caption,
          description
        }
      }
    }

    return new Promise((resolve, reject) => {
      commitMutation(environment, {
        mutation,
        variables,
        onCompleted: (rsp, errors) => {
          if (errors) {
            console.log('failed', errors)
            reject(errors)
          } else {
            resolve(rsp.createHubPhoto.hubPhoto)
          }
        },
        onError: err => {
          console.log('errored', err)
          reject(err)
        }
      })
    })
  }

  delete = ({ id }) => {
    const mutation = graphql`
      mutation HubPhotosDeleteMutation($input: DeleteHubPhotoByIdInput!) {
        deleteHubPhotoById(input: $input) {
          hubPhoto {
            id
          }
        }
      }
    `

    const variables = {
      input: {
        id
      }
    }

    return new Promise((resolve, reject) => {
      commitMutation(environment, {
        mutation,
        variables,
        onCompleted: (rsp, errors) => {
          if (errors) {
            console.log('failed', errors)
            reject(errors)
          } else {
            resolve(rsp)
          }
        },
        onError: err => {
          console.log('errored', err)
          reject(err)
        }
      })
    })
  }

  onHubPictureUploadFinished = async rsp => {
    console.log(`got back image ${rsp.url}`, rsp)
    const { id } = await this.create({
      hubId: this.props.hubId,
      url: rsp.url,
      caption: '',
      description: ''
    })
    this.uploadInput.value = ''

    let patchedPhotos = this.state.photos.edges.slice()
    patchedPhotos.push({ node: { id, url: rsp.url } })

    this.setState({
      photos: { edges: patchedPhotos }
    })
  }

  deletePhoto = async id => {
    const deletionConfirmed = window.confirm(
      `Are you sure you want to delete this photo?`
    )
    if (deletionConfirmed) {
      return await this.delete({ id })
    }

    return false
  }

  // handleMakeVariation = async (id, url) => {
  //   const resizedUrl = imageResizerUrl(url, {
  //     width: 640,
  //     height: 640,
  //     fit: 'crop',
  //     dpr: 2
  //   })
  //   console.log('got resizedUrl', resizedUrl)
  //   try {
  //     const response = await fetch(resizedUrl)
  //     console.log('fetched')
  //     const arrayBuffer = await response.arrayBuffer()
  //     // const body = response.body
  //     console.log('buffered')
  //     const buffer = Buffer.from(arrayBuffer)
  //     buffer.name = 'image.png'
  //     console.log('changed into real buffer')
  //     const rsp = await openai.createImageVariation(buffer, 1, '1024x1024', 'url', 'Familio Admin').catch(err => console.error(err))
  //     console.log('created variation')
  //     const image_url = rsp?.data.data[0].url
  //     console.log('got image_url', image_url)
  //     // const reader = new FileReader()
  //     // reader.onload = event => {
  //     //   setImageData(event.target.result)
  //     // }
  //     // reader.readAsArrayBuffer(new Blob([arrayBuffer]))
  //   } catch (error) {
  //     console.error(error)
  //   }
  //   return
  // }

  renderPhoto = ({ node: { id, url, caption } }) => {
    const resizedUrl = imageResizerUrl(url, {
      width: 96,
      height: 72,
      fit: 'crop',
      dpr: 2
    })

    return (
      <div key={id} className='col-md-6 col-lg-4 col-xl-3 mb-4'>
        <a href={url} target='_blank' rel='noopener noreferrer'>
          <img
            src={resizedUrl}
            alt={caption || ''}
            style={{ width: 96, height: 72, borderRadius: 2 }}
          />
        </a>
        <div>
          {/* <a
            href='?'
            onClick={e => {
              e.preventDefault()
              this.handleMakeVariation(id, url)
            }}
          >
            variation
          </a> */}
          <a
            href='?'
            className='p-2'
            onClick={e => {
              e.preventDefault()
              this.deletePhoto(id)
            }}
          >
            delete
          </a>
        </div>
      </div>
    )
  }

  renderPhotos = photos => {
    if (!photos || photos.edges.length === 0) {
      return null
    }

    return (
      <div id='photos' className='row row-cards'>
        {photos.edges.map(this.renderPhoto)}
      </div>
    )
  }

  render () {
    const { photos } = this.state

    return (
      <Form>
        {this.renderPhotos(photos)}
        {photos.edges.length < 4 ? (
          <p>
            <small>
              Consider adding at least four photos. Each photo must be a
              reasonable dimension i.e. should not be below at least 640x640
            </small>
          </p>
        ) : null}
        <div className='mt-4'>
          <div className='custom-file'>
            <ReactS3Uploader
              className='custom-file-input'
              inputRef={ref => (this.uploadInput = ref)}
              signingUrl={`${config.apiUrl}/sign-s3`}
              signingUrlMethod='GET'
              accept='image/*'
              preprocess={this.onUploadStart}
              onProgress={this.onUploadProgress}
              onError={this.onUploadError}
              onFinish={this.onHubPictureUploadFinished}
              contentDisposition='auto'
              scrubFilename={this.scrubFilename}
            />
            <label className='custom-file-label'>Choose file</label>
          </div>
        </div>
      </Form>
    )
  }
}

const HubPhotosContainer = createFragmentContainer(HubPhotos, {
  photos: graphql`
    fragment HubPhotos_photos on HubPhotosConnection {
      edges {
        node {
          id
          url
          caption
          description
        }
      }
    }
  `
})

export { HubPhotosContainer as HubPhotos }
