import graphql from 'babel-plugin-relay/macro'
import React, { Component } from 'react'
import { Col, Form, FormControl, FormGroup, FormLabel } from 'react-bootstrap'
import { commitMutation, createFragmentContainer } from 'react-relay'
import ReactS3Uploader from 'react-s3-uploader'
import shortid from 'shortid'

import environment from '../Environment'
import Config from '../config'
import { theme } from '../helpers'

const config = Config(process.env.REACT_APP_ENV)

class User extends Component {
  constructor (props) {
    super(props)

    this.state = {
      enabled: true,
      form: {
        ...(props.user || {})
      }
    }
  }

  scrubFilename = filename => `${shortid.generate()}-${filename.replace(/[^\w\d_\-.]+/ig, '')}`

  update = () => {
    const mutation = graphql`
      mutation UserUpdateMutation($input: UpdateUserByIdInput!) {
        updateUserById(input: $input) {
          user {
            id
          }
        }
      }
    `

    const variables = {
      input: {
        id: this.props.user.id,
        userPatch: {
          ...this.state.form
        }
      }
    }

    return new Promise((resolve, reject) => {
      commitMutation(
        environment, {
          mutation,
          variables,
          onCompleted: (rsp, errors) => {
            if (errors) {
              console.log('failed', errors)
              reject(errors)
            } else {
              resolve(rsp)
            }
          },
          onError: err => {
            console.log('errored', err)
            reject(err)
          }
        }
      )
    })
  }

  delete = () => {
    const mutation = graphql`
      mutation UserDeleteMutation($input: DeleteUserByIdInput!) {
        deleteUserById(input: $input) {
          user {
            id
          }
        }
      }
    `

    const variables = {
      input: {
        id: this.props.user.id
      }
    }

    return new Promise((resolve, reject) => {
      commitMutation(
        environment, {
          mutation,
          variables,
          onCompleted: (rsp, errors) => {
            if (errors) {
              console.log('failed', errors)
              reject(errors)
            } else {
              resolve(rsp)
            }
          },
          onError: err => {
            console.log('errored', err)
            reject(err)
          }
        }
      )
    })
  }

  saveChanges = () => this.update()

  handleChange = user => {
    const { name, value } = user.target
    this.setState({ form: { ...this.state.form, [name]: value } })
  }

  onUploadProgress = rsp => this.setState({ enabled: false })

  onUploadFinish = rsp => this.setState({ enabled: true, form: { ...this.state.form, pictureUrl: rsp.url } })

  renderField = (title, key, opts = {}) => {
    return (
      <FormGroup>
        <Col componentClass={FormLabel} style={theme.styles.label}>{title}</Col>
        <Col>
          <FormControl name={key} {...opts} placeholder={title} value={this.state.form[key] || ''} onChange={this.handleChange} />
        </Col>
      </FormGroup>
    )
  }

  renderHeader = title => <h5 style={theme.styles.header}>{title}</h5>

  render () {
    const { enabled } = this.state

    return (
      <Form horizontal>
        {this.renderField('First Name', 'firstName', { })}
        {this.renderField('Last Name', 'lastName', { })}
        {this.renderField('Phone', 'phone', { type: 'phone', autoComplete: 'tel-national' })}
        {this.renderField('Email', 'email', { type: 'email', autoComplete: 'email' })}

        {this.renderField('activeFamilyId', 'activeFamilyId')}

        <FormGroup>
          <Col componentClass={FormLabel} style={theme.styles.label}>
            Picture Url
          </Col>
          <Col>
            {!enabled && <div>Uploading</div>}
            <ReactS3Uploader
              signingUrl={`${config.apiUrl}/sign-s3`}
              signingUrlMethod='GET'
              accept='image/*'
              onProgress={this.onUploadProgress}
              onFinish={this.onUploadFinish}
              contentDisposition='auto'
              scrubFilename={this.scrubFilename} />
          </Col>
        </FormGroup>
      </Form>
    )
  }
}

const UserContainer = createFragmentContainer(User, {
  user: graphql`
    fragment User_user on User {
      id
      firstName
      lastName
      phone
      email
      pictureUrl
      activeFamilyId
    }
  ` }
)

export { UserContainer as User }
