import React, { Component } from 'react'
import { QueryRenderer } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import { colors, Icon, Text, StatsCard, Card } from 'tabler-react'
import C3Chart from 'react-c3js'
import moment from 'moment'
import _ from 'lodash'

import environment from '../Environment'
import { Error } from '../components'

class Dashboard extends Component {
  renderStatsCard = ({ movement, total, label, data, color }) => {
    return (
      <StatsCard
        layout={2}
        movement={movement}
        total={total}
        label={label}
        chart={
          <C3Chart
            style={{ height: '100%' }}
            padding={{
              bottom: -10,
              left: -1,
              right: -1,
            }}
            data={{
              names: { data1: label },
              columns: [["data1", ...data.map(d => d.estimated || d.count)]],
              type: 'area',
            }}
            tooltip={{
              format: {
                title: function(idx) {
                  const cnt = data[idx]
                  return new Date(parseInt(cnt.date)).toLocaleDateString()
                },
                value: function (value, ratio, id, index) {
                  const { estimated, count } = data[index]
                  return estimated
                    ? `${count} (~${Math.round(estimated)})`
                    : count
                }
              },
            }}
            legend={{ show: false }}
            transition={{ duration: 0 }}
            point={{ show: false }}
            axis={{
              y: {
                padding: {
                  bottom: 0,
                },
                show: false,
                tick: {
                  outer: false,
                },
              },
              x: {
                show: false,
                padding: {
                  left: 0,
                  right: 0,
                }
              },
            }}
            color={{
              pattern: [color || '#467fcf'],
            }}
          />
        }
      />
    )
  }

  render () {
    return (
      <QueryRenderer
        environment={environment}
        query={graphql`
          query DashboardQuery {
            appStats {
              totalUsers
              totalUsersOneWeekAgo
              totalUsersWithPushEnabled
              totalIosUsers
              totalAndroidUsers
              totalFamilies
              totalContacts
              totalEvents
              totalConversations
              totalMessages
              totalMessagesOneWeekAgo
              averageMessagesByConversations {
                conversationType
                averageMessages
              }
              usersLastDay
              usersLastWeek
              usersLastMonth
              usersLastMonthByAuthenticationType {
                phone
                email
                facebook
                google
                apple
              }
              usersDeletedLastMonth
              familiesWithPhones
              familiesWithEmails
              familiesMembersCount {
                membersCount
                familiesCount
              }
              usersCountByDay {
                date
                count
              }
              usersCountByWeek {
                date
                count
              }
              usersCountByMonth {
                date
                count
              }
              messagesCountByDay {
                date
                count
              }
              messagesCountByWeek {
                date
                count
              }
              messagesCountByMonth {
                date
                count
              }
            }
          }
        `}
        render={({ error, props }) => {
          if (error) return <Error error={error} />

          if (!props) {
            return (
              <div className='my-3 my-md-5'>
                <div className='container'>
                  <div className='loader' />
                </div>
              </div>
            )
          }

          const usersWeeklyMovement = ((props.appStats.totalUsers - props.appStats.totalUsersOneWeekAgo) / props.appStats.totalUsersOneWeekAgo * 100).toFixed(1)
          const messagesWeeklyMovement = ((props.appStats.totalMessages - props.appStats.totalMessagesOneWeekAgo) / props.appStats.totalMessagesOneWeekAgo * 100).toFixed(1)

          const usersCountByDay = props.appStats.usersCountByDay.slice(0, 14).map((a, idx) => {
            if (idx !== 0) return a

            // estimate value
            const startOfDay = moment(new Date(parseInt(a.date)))
            const hours = Math.max(moment().diff(startOfDay, 'hours'), 1)
            return ({ ...a, estimated: a.count * (24 / hours) })
          }).reverse()
          const usersCountByWeek = props.appStats.usersCountByWeek.slice(0, 24).map((a, idx) => {
            if (idx !== 0) return a

            // estimate value
            const startOfWeek = moment(new Date(parseInt(a.date)))
            const days = Math.max(moment().diff(startOfWeek, 'days'), 1)
            return ({ ...a, estimated: a.count * (7 / days) })
          }).reverse()
          const usersCountByMonth = props.appStats.usersCountByMonth.slice(0, 12).map((a, idx) => {
            if (idx !== 0) return a

            // estimate value
            const startOfMonth = moment(new Date(parseInt(a.date)))
            const days = Math.max(moment().diff(startOfMonth, 'days'), 1)
            return ({ ...a, estimated: a.count * startOfMonth.daysInMonth() / days })
          }).reverse()

          const messagesCountByDay = props.appStats.messagesCountByDay.slice(0, 14).reverse()
          const messagesCountByWeek = props.appStats.messagesCountByWeek.slice(0, 24).reverse()
          const messagesCountByMonth = props.appStats.messagesCountByMonth.slice(0, 12).reverse()

          const totalFamiliesWithMoreThanOneMember = props.appStats.familiesMembersCount.reduce((x, { membersCount, familiesCount }) => membersCount > 1 ? familiesCount + x : 0, 0)

          return (
            <div className='my-3 my-md-5'>
              <div className='container'>
                <div className='row row-cards'>
                  <div className="col-12 col-lg-4">
                    {this.renderStatsCard({ movement: usersWeeklyMovement, total: props.appStats.totalUsers, label: 'Users', data: usersCountByDay, color: '#467fcf' })}
                  </div>
                  <div className="col-12 col-lg-4">
                    {this.renderStatsCard({ movement: 0, total: totalFamiliesWithMoreThanOneMember, label: 'Families', data: [] })}
                  </div>
                  <div className="col-12 col-lg-4">
                    {this.renderStatsCard({ movement: messagesWeeklyMovement, total: props.appStats.totalMessages, label: 'Messages', data: messagesCountByDay, color: 'orange' })}
                  </div>
                </div>

                <div className='row row-cards'>
                  <div className="col-12 col-lg-4">
                    <Card title="Stats for Users">
                      <div className="card-status bg-blue"></div>

                      <table className="table card-table">
                        <tbody>
                          <tr>
                            <td className='min-width'><Icon prefix="fa" name="calendar-day" className="text-muted" /></td>
                            <td>Last 24 Hours</td>
                            <td className="text-right"><Text RootComponent="span" muted>{props.appStats.usersLastDay}</Text></td>
                          </tr>
                          <tr>
                            <td><Icon prefix="fa" name="calendar-week" className="text-muted" /></td>
                            <td>Last Week</td>
                            <td className="text-right"><Text RootComponent="span" muted>{props.appStats.usersLastWeek}</Text></td>
                          </tr>
                          <tr>
                            <td><Icon prefix="fa" name="calendar" className="text-muted" /></td>
                            <td>Last Month</td>
                            <td className="text-right">
                              <Text RootComponent="span" muted>{props.appStats.usersLastMonth}</Text>
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td colSpan="2" style={{ padding: 0 }}>
                              <table className="table card-table" style={{ backgroundColor: 'transparent' }}>
                                <thead />
                                <tbody>
                                  <tr>
                                    <td width="1"><Icon prefix="fa" name="phone" className="text-muted" /></td>
                                    <td>Phone</td>
                                    <td className="text-right"><Text RootComponent="span" muted>{props.appStats.usersLastMonthByAuthenticationType.phone}</Text></td>
                                  </tr>
                                  <tr>
                                    <td width="1"><Icon prefix="fa" name="at" className="text-muted" /></td>
                                    <td>Email</td>
                                    <td className="text-right"><Text RootComponent="span" muted>{props.appStats.usersLastMonthByAuthenticationType.email}</Text></td>
                                  </tr>
                                  <tr>
                                    <td width="1"><Icon prefix="fa" name="facebook" className="text-muted" /></td>
                                    <td>Facebook</td>
                                    <td className="text-right"><Text RootComponent="span" muted>{props.appStats.usersLastMonthByAuthenticationType.facebook}</Text></td>
                                  </tr>
                                  <tr>
                                    <td width="1"><Icon prefix="fa" name="google" className="text-muted" /></td>
                                    <td>Google</td>
                                    <td className="text-right"><Text RootComponent="span" muted>{props.appStats.usersLastMonthByAuthenticationType.google}</Text></td>
                                  </tr>
                                  <tr>
                                    <td width="1"><Icon prefix="fa" name="apple" className="text-muted" /></td>
                                    <td>Apple</td>
                                    <td className="text-right"><Text RootComponent="span" muted>{props.appStats.usersLastMonthByAuthenticationType.apple}</Text></td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td><Icon prefix="fa" name="fire" className="text-muted" /></td>
                            <td>Deleted Last Month</td>
                            <td className="text-right"><Text RootComponent="span" muted>{props.appStats.usersDeletedLastMonth}</Text></td>
                          </tr>
                          <tr>
                            <td><Icon prefix="fa" name="bell" className="text-muted" /></td>
                            <td>Push Enabled</td>
                            <td className="text-right"><Text RootComponent="span" muted>{props.appStats.totalUsersWithPushEnabled}</Text></td>
                          </tr>
                          <tr>
                            <td><Icon prefix="fa" name="apple" className="text-muted" /></td>
                            <td>iOS</td>
                            <td className="text-right"><Text RootComponent="span" muted>{props.appStats.totalIosUsers}</Text></td>
                          </tr>
                          <tr>
                            <td><Icon prefix="fa" name="android" className="text-muted" /></td>
                            <td>Android</td>
                            <td className="text-right"><Text RootComponent="span" muted>{props.appStats.totalAndroidUsers}</Text></td>
                          </tr>
                        </tbody>
                      </table>
                    </Card>
                  </div>

                  <div className="col-12 col-lg-4">
                    <Card title="Stats for Families">
                      <div className="card-status bg-green"></div>

                      <table className="table card-table">
                        <tbody>
                          <tr>
                            <td className='min-width'><i className="fa fa-phone text-muted"></i></td>
                            <td>Phones</td>
                            <td className="text-right"><Text RootComponent="span" muted>{props.appStats.familiesWithPhones}</Text></td>
                          </tr>
                          <tr>
                            <td><i className="fa fa-envelope text-muted"></i></td>
                            <td>Emails</td>
                            <td className="text-right"><Text RootComponent="span" muted>{props.appStats.familiesWithEmails}</Text></td>
                          </tr>
                          <tr>
                            <td><i className="fa fa-users text-muted"></i></td>
                            <td colSpan="2">With members count</td>
                          </tr>
                          <tr>
                            <td />
                            <td colSpan="2" style={{ padding: 0 }}>
                              <table className="table card-table" style={{ backgroundColor: 'transparent' }}>
                                <tbody>
                          {props.appStats.familiesMembersCount.map(({ membersCount, familiesCount }) => {
                            return (
                              <tr key={membersCount}>
                                <td>{membersCount}</td>
                                <td className="text-right"><Text RootComponent="span" muted>{familiesCount}</Text></td>
                              </tr>
                            )
                          })}
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Card>
                  </div>

                  <div className="col-12 col-lg-4">
                    <Card title="Stats for Messages">
                      <div className="card-status bg-orange"></div>

                      <table className="table card-table">
                        <tbody>
                          <tr>
                            <td className='min-width'><Icon prefix="fa" name="balance-scale" className="text-muted" /></td>
                            <td>Average per week</td>
                            <td className="text-right text-muted">{Math.round(messagesCountByWeek.reduce((v, c) => v + c.count, 0) / messagesCountByWeek.length)}</td>
                          </tr>
                          <tr>
                            <td className='min-width'><Icon prefix="fa" name="balance-scale" className="text-muted" /></td>
                            <td>Average per month</td>
                            <td className="text-right text-muted">{Math.round(messagesCountByMonth.reduce((v, c) => v + c.count, 0) / messagesCountByMonth.length)}</td>
                          </tr>
                          <tr>
                            <td className='min-width'><Icon prefix="fa" name="balance-scale" className="text-muted" /></td>
                            <td colSpan={2}>Average number of messages per ...</td>
                          </tr>
                          {_.orderBy(props.appStats.averageMessagesByConversations, ({ conversationType }) => conversationType).map(({ conversationType, averageMessages }) => (
                            <tr key={conversationType}>
                              <td />
                              <td>... <b>{_.startCase(conversationType)}</b></td>
                              <td className="text-right"><Text RootComponent="span" muted>{_.round(averageMessages, 2)}</Text></td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Card>
                  </div>
                </div>

                <div className='row row-cards'>
                  <div className="col-12 col-lg-4">
                    <Card title={`Users By Week · last ${usersCountByWeek.length} weeks`}>
                      <C3Chart
                        style={{ height: '240px' }}
                        data={{
                          columns: [
                            ["data1", ...usersCountByWeek.map(d => d.estimated || d.count)]
                          ],
                          type: "area",
                          colors: {
                            data1: colors['#467fcf'],
                          },
                          names: {
                            data1: 'Users'
                          },
                        }}
                        axis={{
                          y: {
                            padding: {
                              bottom: 0,
                            },
                            show: false,
                            tick: {
                              outer: false,
                            },
                          },
                          x: {
                            show: false,
                            padding: {
                              left: 0,
                              right: 0,
                            }
                          },
                        }}
                        legend={{ show: false }}
                        point={{ show: false }}
                        tooltip={{
                          format: {
                            title: function(idx) {
                              const cnt = usersCountByWeek[idx]
                              return new Date(parseInt(cnt.date)).toLocaleDateString()
                            },
                            value: function (value, ratio, id, index) {
                              const metric = usersCountByWeek[index]
                              return metric.estimated
                                ? `${metric.count} (~${Math.round(metric.estimated)})`
                                : metric.count
                            }
                          },
                        }}
                        padding={{
                          bottom: -10,
                          left: -1,
                          right: -1
                        }}
                      />
                    </Card>

                    <Card title={`Users By Month · last ${usersCountByMonth.length} months`}>
                      <C3Chart
                        style={{ height: '240px' }}
                        data={{
                          columns: [
                            ["data1", ...usersCountByMonth.map(d => d.estimated || d.count)],
                          ],
                          type: "area",
                          colors: {
                            data1: colors['#467fcf'],
                          },
                          names: {
                            data1: "Users",
                          }
                        }}
                        axis={{
                          y: {
                            padding: {
                              bottom: 0,
                            },
                            show: false,
                            tick: {
                              outer: false,
                            },
                          },
                          x: {
                            show: false,
                            padding: {
                              left: 0,
                              right: 0,
                            }
                          },
                        }}
                        legend={{ show: false }}
                        point={{ show: false }}
                        tooltip={{
                          format: {
                            title: function(idx) {
                              const monthShortNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                              const cnt = usersCountByMonth[idx]
                              const d = new Date(parseInt(cnt.date))
                              return monthShortNames[d.getMonth()]
                              // return new Date(parseInt(cnt.date)).toLocaleDateString()
                            },
                            value: function (value, ratio, id, index) {
                              const metric = usersCountByMonth[index]
                              return metric.estimated
                                ? `${metric.count} (~${Math.round(metric.estimated)})`
                                : metric.count
                            }
                          },
                        }}
                        padding={{
                          bottom: -10,
                          left: -1,
                          right: -1
                        }}
                      />
                    </Card>
                  </div>

                  <div className="col-12 col-lg-4">
                  </div>

                  <div className="col-12 col-lg-4">
                    <Card title='Messages By Week'>
                      <C3Chart
                        style={{ height: '240px' }}
                        data={{
                          columns: [ ["data1", ...messagesCountByWeek.map(d => d.count)] ],
                          type: "area",
                          colors: { data1: colors["orange"] },
                          names: { data1: "Messages" },
                        }}
                        axis={{
                          y: {
                            padding: {
                              bottom: 0,
                            },
                            show: false,
                            tick: {
                              outer: false,
                            },
                          },
                          x: {
                            show: false,
                            padding: {
                              left: 0,
                              right: 0,
                            }
                          },
                        }}
                        legend={{ show: false }}
                        point={{ show: false }}
                        tooltip={{
                          format: {
                            title: function(idx) {
                              const cnt = messagesCountByWeek[idx]
                              return new Date(parseInt(cnt.date)).toLocaleDateString()
                            },
                          },
                        }}
                        padding={{
                          bottom: -10,
                          left: -1,
                          right: -1
                        }}
                      />
                    </Card>

                    {/* <Card title={`${Math.round(messagesCountByMonth.reduce((v, c) => v + c.count, 0) / messagesCountByMonth.length)} average messages/month`}> */}
                    <Card title='Messages By Month'>
                      <C3Chart
                        style={{ height: '240px' }}
                        data={{
                          columns: [ ["data1", ...messagesCountByMonth.map(d => d.count)] ],
                          type: "area",
                          colors: { data1: colors["orange"] },
                          names: { data1: "Messages" },
                        }}
                        axis={{
                          y: {
                            padding: {
                              bottom: 0,
                            },
                            show: false,
                            tick: {
                              outer: false,
                            },
                          },
                          x: {
                            show: false,
                            padding: {
                              left: 0,
                              right: 0,
                            }
                          },
                        }}
                        legend={{ show: false }}
                        point={{ show: false }}
                        tooltip={{
                          format: {
                            title: function(idx) {
                              const monthShortNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                              const cnt = messagesCountByMonth[idx]
                              const d = new Date(parseInt(cnt.date))
                              return monthShortNames[d.getMonth()]
                              // return new Date(parseInt(cnt.date)).toLocaleDateString()
                            },
                          },
                        }}
                        padding={{
                          bottom: -10,
                          left: -1,
                          right: -1
                        }}
                      />
                    </Card>
                  </div>
                </div>

                <div className="row row-cards">
                  <div className="col-12 col-lg-4">
                    <Card title="Stats for Contacts">
                      <div className="card-status bg-pink"></div>

                      <table className="table card-table">
                        <tbody>
                          <tr>
                            <td className='min-width'><i className="fa fa-user text-muted"></i></td>
                            <td>Contacts</td>
                            <td className="text-right"><Text RootComponent="span" muted>{props.appStats.totalContacts}</Text></td>
                          </tr>
                        </tbody>
                      </table>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
          )
        }}
      />
    )
  }
}

export { Dashboard }
