import React, { Component } from 'react'

class AppsList extends Component {
  render () {
    return (
      <div>
        <h1>Apps</h1>

        <table className='table'>
          <thead>
            <tr>
              <th>Name</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Address</th>
              <th>Latitude</th>
              <th>Longitude</th>
            </tr>
          </thead>
          <tbody>
          </tbody>
        </table>
      </div>
    )
  }
}

export { AppsList }
