/**
 * @generated SignedSource<<c484f4e155188befec4a15539715bd9c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": "STARTS_AT_DESC"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EventsQuery",
    "selections": [
      {
        "alias": "events",
        "args": (v0/*: any*/),
        "concreteType": "EventsConnection",
        "kind": "LinkedField",
        "name": "allEvents",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EventsList_events"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EventsMap_events"
          }
        ],
        "storageKey": "allEvents(orderBy:\"STARTS_AT_DESC\")"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "EventsQuery",
    "selections": [
      {
        "alias": "events",
        "args": (v0/*: any*/),
        "concreteType": "EventsConnection",
        "kind": "LinkedField",
        "name": "allEvents",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Event",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "address",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startsAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endsAt",
                "storageKey": null
              },
              {
                "alias": "owner",
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "userByOwnerId",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pictureUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pictureThumbnailUrl",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": "family",
                "args": null,
                "concreteType": "Family",
                "kind": "LinkedField",
                "name": "familyByFamilyId",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "latitude",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "longitude",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "allEvents(orderBy:\"STARTS_AT_DESC\")"
      }
    ]
  },
  "params": {
    "cacheID": "8e4c7371c70bffe75e81fd690d130525",
    "id": null,
    "metadata": {},
    "name": "EventsQuery",
    "operationKind": "query",
    "text": "query EventsQuery {\n  events: allEvents(orderBy: STARTS_AT_DESC) {\n    totalCount\n    ...EventsList_events\n    ...EventsMap_events\n  }\n}\n\nfragment EventsList_events on EventsConnection {\n  totalCount\n  nodes {\n    id\n    address\n    startsAt\n    endsAt\n    owner: userByOwnerId {\n      id\n      firstName\n      lastName\n      pictureUrl\n      pictureThumbnailUrl\n    }\n    family: familyByFamilyId {\n      name\n      id\n    }\n  }\n}\n\nfragment EventsMap_events on EventsConnection {\n  totalCount\n  nodes {\n    id\n    latitude\n    longitude\n  }\n}\n"
  }
};
})();

node.hash = "813385908d812a74ac0219154cc5571c";

module.exports = node;
