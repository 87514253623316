import { Cookies } from 'react-cookie'
import { Environment, Network, RecordSource, Store } from 'relay-runtime'

import Config from './config'
const config = Config(process.env.REACT_APP_ENV)

const source = new RecordSource()
const store = new Store(source)

const headersWithToken = token => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }

  if (token) {
    return {
      ...headers,
      Authorization: `Bearer ${token}`
    }
  }

  return headers
}

const authenticatedFetch = async (
  operation,
  variables,
  cacheConfig,
  uploadables,
  url
) => {
  try {
    const cookies = new Cookies()

    const token = cookies.get('jwt')
    const rsp = await fetch(url, {
      method: 'POST',
      headers: headersWithToken(token),
      body: JSON.stringify({
        query: operation.text,
        variables
      })
    })

    if (rsp.ok) {
      return rsp.json()
    }

    if (rsp.status === 401 || rsp.status === 403) {
      return rsp.json()
    }

    const text = await rsp.text()

    console.error(`Failed querying GraphQL: ${text}`)
    return {}
  } catch (err) {
    console.error(`Failed to fetch query from ${url}`, err)
  }
}

const fetchQuery = (operation, variables, cacheConfig, uploadables) =>
  authenticatedFetch(
    operation,
    variables,
    cacheConfig,
    uploadables,
    `${config.apiUrl}/api/graphql`
  )

const network = Network.create(fetchQuery)

const environment = new Environment({
  network,
  store
})

export default environment
