/**
 * @generated SignedSource<<25bacfc409af13879b606dd60654817c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteHubPayload",
    "kind": "LinkedField",
    "name": "deleteHubById",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Hub",
        "kind": "LinkedField",
        "name": "hub",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HubDeleteMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HubDeleteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "484fdc8fce0de375612c4b6990992938",
    "id": null,
    "metadata": {},
    "name": "HubDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation HubDeleteMutation(\n  $input: DeleteHubByIdInput!\n) {\n  deleteHubById(input: $input) {\n    hub {\n      id\n    }\n  }\n}\n"
  }
};
})();

node.hash = "ebb644b126a97bf1481c0d1ecade154c";

module.exports = node;
