import React, { Component } from 'react'
import { createFragmentContainer } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps'

import { compose } from 'recompose'

import Config from '../config'
const config = Config(process.env.REACT_APP_ENV)

const MapWithAMarker = compose(
  withScriptjs,
  withGoogleMap
)(props =>
  <GoogleMap defaultZoom={7} defaultCenter={{ lat: 56.2128552, lng: 10.3002264 }}>
    {/* {props.markers.map(m => <Circle key={m.id} defaultRadius={2500} center={{ lat: m.latitude, lng: m.longitude }} />)} */}
    {props.markers.filter(partner => (partner.latitude && partner.longitude)).map(partner => <Marker key={partner.id} position={{ lat: partner.latitude, lng: partner.longitude }} onClick={() => (window.location.href = `/admin/events/${partner.id}/edit`)} />)}
  </GoogleMap>
)

class EventsMap extends Component {
  render () {
    const { events } = this.props
    return (
      <div className='map-header'>
        <MapWithAMarker
          markers={events.nodes.map(n => ({ id: n.id, latitude: n.latitude, longitude: n.longitude }))}
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${config.googleApiKey}`}
          loadingElement={<div style={{ height: '100%' }} />}
          containerElement={<div style={{ height: '480px', marginBottom: 64 }} />}
          mapElement={<div className='map-header-layer' style={{ height: '100%' }} />}
        />
      </div>
    )
  }
}

const EventsMapContainer = createFragmentContainer(EventsMap, {
  events: graphql`
    fragment EventsMap_events on EventsConnection {
      totalCount
      nodes {
        id
        latitude
        longitude
      }
    }
  ` 
}
)
export { EventsMapContainer as EventsMap }
