import React, { Component } from 'react'
import { createFragmentContainer } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import { Link } from 'react-router-dom'
import moment from 'moment'

class EventsList extends Component {
  renderOwner = ({ owner, family }) => owner ? `${owner.firstName} ${owner.lastName}` : family.name

  renderEventRow = event => {
    return (
      <tr key={event.id}>
        <td><Link className='icon' to={`/events/${event.id}`}><i className='fe fe-edit'></i></Link></td>
        <td>{this.renderOwner(event)}</td>
        <td>{event.address || '-'}</td>
        <td>{moment(event.startsAt).format('MMM D YYYY, LT')}</td>
        <td>{moment(event.endsAt).format('MMM D YYYY, LT')}</td>
      </tr>
    )
  }

  render () {
    return (
      <div className='row row-cards'>
        <div className='col-lg-12'>
          <div className='card'>
            <div className='card-header'>
              <h3 className='card-title'>Events ({this.props.events.totalCount})</h3>
            </div>
            <div className='table-responsive'>
              <table className='table card-table table-striped table-vcenter'>
                <thead>
                  <tr>
                    <th width='1'></th>
                    <th>Owner</th>
                    <th>Address</th>
                    <th>Starts At</th>
                    <th>Ends At</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.events.nodes.map(this.renderEventRow)}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const EventsListContainer = createFragmentContainer(EventsList, {
  events: graphql`
    fragment EventsList_events on EventsConnection {
      totalCount
      nodes {
        id
        address
        startsAt
        endsAt

        owner: userByOwnerId {
          id
          firstName
          lastName
          pictureUrl
          pictureThumbnailUrl
        }

        family: familyByFamilyId {
          name
        }
      }
    }
  ` }
)
export { EventsListContainer as EventsList }
