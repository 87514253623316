import graphql from 'babel-plugin-relay/macro'
import React, { Component } from 'react'
import { Col, Form } from 'react-bootstrap'
import { commitMutation, createFragmentContainer } from 'react-relay'
import ReactS3Uploader from 'react-s3-uploader'
import shortid from 'shortid'

import environment from '../Environment'
import Config from '../config'
import { slugify, theme } from '../helpers'

const config = Config(process.env.REACT_APP_ENV)

const sanitize = ({
  name,
  description,
  siteUrl,
  bookingUrl,
  primaryColor,
  secondaryColor,
  facebookUrl,
  twitterUrl,
  instagramUrl,
  youtubeUrl,
  vimeoUrl,
  linkedinUrl,
  slug,
  phone,
  email,
  logoUrl,
  coverPhotoUrl,
  address,
  latitude,
  longitude,
  city,
  country
}) => ({
  name,
  description,
  siteUrl,
  bookingUrl,
  primaryColor,
  secondaryColor,
  facebookUrl,
  twitterUrl,
  instagramUrl,
  youtubeUrl,
  vimeoUrl,
  linkedinUrl,
  slug,
  phone,
  email,
  logoUrl,
  coverPhotoUrl,
  address,
  latitude,
  longitude,
  city,
  country
})

class Partner extends Component {
  constructor (props) {
    super(props)

    this.state = {
      form: {
        ...sanitize(props.partner || {})
      }
    }
  }

  create = () => {
    return new Promise((resolve, reject) => {
      commitMutation(environment, {
        mutation: graphql`
          mutation PartnerCreateMutation($input: CreatePartnerInput!) {
            createPartner(input: $input) {
              partner {
                id
              }
            }
          }
        `,
        variables: {
          input: {
            partner: {
              ...this.state.form,
              slug: slugify(this.state.form.name)
            }
          }
        },
        onCompleted: (rsp, errors) => {
          if (errors) {
            console.log('failed', errors)
            reject(errors)
          } else {
            resolve(rsp)
          }
        },
        onError: err => {
          console.log('errored', err)
          reject(err)
        }
      })
    })
  }

  update = () => {
    return new Promise((resolve, reject) => {
      commitMutation(environment, {
        mutation: graphql`
          mutation PartnerUpdateMutation($input: UpdatePartnerByIdInput!) {
            updatePartnerById(input: $input) {
              partner {
                id
              }
            }
          }
        `,
        variables: {
          input: {
            id: this.props.partner.id,
            partnerPatch: {
              ...this.state.form,
              latitude: this.state.form.latitude
                ? parseFloat(this.state.form.latitude)
                : null,
              longitude: this.state.form.longitude
                ? parseFloat(this.state.form.longitude)
                : null,
              slug: slugify(this.state.form.slug) // ensure we are not messing up slug on update
            }
          }
        },
        onCompleted: (rsp, errors) => {
          // console.log(rsp, errors)
          if (errors) {
            console.log('failed', errors)
            reject(errors)
          } else {
            resolve(rsp)
          }
        },
        onError: err => {
          console.log('errored', err)
          reject(err)
        }
      })
    })
  }

  delete = () => {
    return new Promise((resolve, reject) => {
      commitMutation(environment, {
        mutation: graphql`
          mutation PartnerDeleteMutation($input: DeletePartnerByIdInput!) {
            deletePartnerById(input: $input) {
              partner {
                id
              }
            }
          }
        `,
        variables: {
          input: {
            id: this.props.partner.id
          }
        },
        onCompleted: (rsp, errors) => {
          if (errors) {
            console.log('failed', errors)
            reject(errors)
          } else {
            resolve(rsp)
          }
        },
        onError: err => {
          console.log('errored', err)
          reject(err)
        }
      })
    })
  }

  isNew = () => this.props.partner === null || this.props.partner.id === null

  saveChanges = () => (this.isNew() ? this.create() : this.update())

  onUploadProgress = rsp => console.log('got onUploadProgress()', rsp)

  onUploadError = rsp => console.log('got onUploadError()', rsp)

  onUploadFinish = rsp =>
    this.setState({ form: { ...this.state.form, logoUrl: rsp.url } })

  scrubFilename = filename =>
    `${shortid.generate()}-${filename.replace(/[^\w\d_\-.]+/gi, '')}`

  handleChange = partner => {
    const { name, value } = partner.target
    this.setState({ form: { ...this.state.form, [name]: value } })
  }

  renderVisitUrl = url => {
    return (
      <a
        href={url}
        rel='noopener noreferrer'
        target='_blank'
        style={{ marginLeft: 10 }}
      >
        <i className='fe fe-chrome' />
      </a>
    )
  }

  renderField = (title, key, opts = {}, children = null) => {
    return (
      <Form.Group>
        <Form.Label style={theme.styles.label}>
          {title}
          {opts['type'] === 'url' &&
            this.state.form[key] &&
            this.renderVisitUrl(this.state.form[key])}
        </Form.Label>
        <Form.Control
          name={key}
          {...opts}
          placeholder={title}
          value={this.state.form[key] || ''}
          onChange={this.handleChange}
        />
        {children}
      </Form.Group>
    )
  }

  renderImage = (form, key) => {
    return (
      <Col>
        <img className='img-responsive' width='96' src={form[key]} alt='' />
        <button
          className='btn btn-danger btn-sm'
          onClick={() => this.setState({ form: { ...form, [key]: null } })}
        >
          Remove
        </button>
      </Col>
    )
  }

  renderUploadImage = (form, key) => {
    return (
      <Col>
        <div className='custom-file'>
          <ReactS3Uploader
            className='custom-file-input'
            signingUrl={`${config.apiUrl}/sign-s3`}
            signingUrlMethod='GET'
            accept='image/*'
            onProgress={this.onUploadProgress}
            onError={this.onUploadError}
            onFinish={rsp =>
              this.setState({ form: { ...form, [key]: rsp.url } })
            }
            contentDisposition='auto'
            scrubFilename={this.scrubFilename}
          />
          <label className='custom-file-label'>Choose file</label>
        </div>
      </Col>
    )
  }

  renderUploadField = (title, key) => {
    const { form } = this.state

    return (
      <Form.Group>
        <Form.Label style={theme.styles.label}>{title}</Form.Label>
        {form[key]
          ? this.renderImage(form, key)
          : this.renderUploadImage(form, key)}
      </Form.Group>
    )
  }

  renderHeader = title => <h5 style={theme.styles.header}>{title}</h5>

  render () {
    const { partner, mode } = this.props
    if (partner && !mode) {
      return <div>hmm</div>
    } else {
      return (
        <Form>
          <Form.Group>
            <Form.Label style={theme.styles.label}>Name *</Form.Label>
            <Form.Control
              name='name'
              placeholder='Name'
              required
              value={this.state.form.name || ''}
              onChange={this.handleChange}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label style={theme.styles.label}>Description</Form.Label>
            <Form.Control
              style={{ height: 200 }}
              name='description'
              componentClass='textarea'
              placeholder='Description'
              value={this.state.form.description || ''}
              onChange={this.handleChange}
            />
          </Form.Group>

          {this.renderField('Slug', 'slug', { disabled: this.isNew() })}

          {this.renderHeader('Address')}
          {this.renderField('Address', 'address')}
          {this.renderField('Latitude', 'latitude')}
          {this.renderField('Longitude', 'longitude')}
          {this.renderField('City', 'city')}
          {this.renderField('Country', 'country')}

          {this.renderHeader('Branding')}
          {this.renderUploadField('Logo', 'logoUrl')}
          {this.renderField(
            'Primary Color',
            'primaryColor',
            {},
            this.state.form.primaryColor ? (
              <div
                style={{
                  marginTop: 4,
                  width: 16,
                  height: 16,
                  backgroundColor: this.state.form.primaryColor
                }}
              />
            ) : null
          )}
          {this.renderField(
            'Secondary Color',
            'secondaryColor',
            {},
            this.state.form.secondaryColor ? (
              <div
                style={{
                  marginTop: 4,
                  width: 16,
                  height: 16,
                  backgroundColor: this.state.form.secondaryColor
                }}
              />
            ) : null
          )}
          {this.renderUploadField('Cover Photo', 'coverPhotoUrl')}

          {this.renderHeader('Contact')}
          {this.renderField('Phone', 'phone', { type: 'phone' })}
          {this.renderField('Email', 'email', { type: 'email' })}

          {this.renderHeader('Website')}
          {this.renderField('Site Url', 'siteUrl', { type: 'url' })}
          {this.renderField('Booking Url', 'bookingUrl', { type: 'url' })}

          {this.renderHeader('Social Links')}
          {this.renderField('Facebook', 'facebookUrl', { type: 'url' })}
          {this.renderField('Twitter', 'twitterUrl', { type: 'url' })}
          {this.renderField('Instagram', 'instagramUrl', { type: 'url' })}
          {this.renderField('YouTube', 'youtubeUrl', { type: 'url' })}
          {this.renderField('Vimeo', 'vimeoUrl', { type: 'url' })}
          {this.renderField('LinkedIn', 'linkedinUrl', { type: 'url' })}
        </Form>
      )
    }
  }
}

const PartnerContainer = createFragmentContainer(Partner, {
  partner: graphql`
    fragment Partner_partner on Partner {
      id
      name
      description
      logoUrl
      coverPhotoUrl
      siteUrl
      primaryColor
      secondaryColor
      bookingUrl
      facebookUrl
      twitterUrl
      instagramUrl
      youtubeUrl
      vimeoUrl
      linkedinUrl
      slug
      phone
      email
      address
      latitude
      longitude
      city
      country
    }
  `
})

export { PartnerContainer as Partner }
