/**
 * @generated SignedSource<<8360e30cd00fa91cd96771dca37d22fe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "version",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EventsNewQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "EventsNewQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "08b9d3919ef716fb2ab0c8689264606c",
    "id": null,
    "metadata": {},
    "name": "EventsNewQuery",
    "operationKind": "query",
    "text": "query EventsNewQuery {\n  version\n}\n"
  }
};
})();

node.hash = "5451cb02a8357409e3d10650dd5d328f";

module.exports = node;
