import React, { Component } from 'react'
import { createFragmentContainer } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps'

import { compose } from 'recompose'

import Config from '../config'

const config = Config(process.env.REACT_APP_ENV)

const MapWithAMarker = compose(
  withScriptjs,
  withGoogleMap
)(props => {
  console.log('got props', props)
  return (
    <GoogleMap defaultZoom={7} defaultCenter={{ lat: 56.2128552, lng: 10.3002264 }}>
      {/* {props.markers.map(m => <Circle key={m.id} defaultRadius={2500} center={{ lat: m.latitude, lng: m.longitude }} />)} */}
      {props.markers.map(partner => <Marker key={partner.id} position={{ lat: partner.latitude, lng: partner.longitude }} onClick={() => (window.location.href = `/admin/partners/${partner.id}/edit`)} />)}
    </GoogleMap>
  )
})

class PartnersMap extends Component {
  render () {
    const { partners } = this.props
    return (
      <div className='map-header'>
        <MapWithAMarker
          markers={partners.nodes.filter(n => n.latitude && n.longitude).map(n => ({ id: n.id, latitude: n.latitude, longitude: n.longitude }))}
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${config.googleApiKey}`}
          loadingElement={<div style={{ height: '100%' }} />}
          containerElement={<div style={{ height: '480px', marginBottom: 64 }} />}
          mapElement={<div className='map-header-layer' style={{ height: '100%' }} />}
        />
      </div>
    )
  }
}

const PartnersMapContainer = createFragmentContainer(PartnersMap, {
  partners: graphql`
    fragment PartnersMap_partners on PartnersConnection {
      totalCount
      nodes {
        id
        name
        latitude
        longitude
      }
    }
  ` 
}
)

export { PartnersMapContainer as PartnersMap }
