import React from 'react'

const Error = ({ error }) => (
  <div className='my-3 my-md-5'>
    <div className='container'>
      <div className="error">{error && error.message}</div>
    </div>
  </div>
)

export { Error }
