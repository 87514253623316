import React, { Component } from 'react'
import { QueryRenderer, commitMutation } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import { Button, Col, Form, FormGroup, FormControl } from 'react-bootstrap'
import createMutations from 'graphql-auto-mutation'

import environment from '../../Environment'
import { Event, GuestsList, Error, Loader } from '../../components'

class EventsShow extends Component {
  constructor (props) {
    super(props)

    this.state = {
      sendingInvites: false,
      importing: false,
      lastUpdatedAt: undefined
    }
  }

  handleChange = event => {
    const { name, value } = event.target
    this.setState({ [name]: value })
  }

  onEdit = () => this.props.history.push(`/events/${this.props.match.params.id}/edit`)

  onPreview = () => {
    alert('TBD')
  }

  onImport = () => {
    this.setState({ importing: true })

    createMutations('/api/graphql').then(api => {
      const eventId = this.props.match.params.id
      const lines = (this.state.importList || '').split('\n')
      lines.forEach(l => {
        api.importUsersForEvent({ eventId, tsvUserLine: l })
          .then(data => {
            this.setState({ importing: false, lastUpdatedAt: new Date(), importList: '' })
          })
          .catch(err => {
            console.error('this is an error', err)
            alert('Import failed. Please follow instructions stricly because validation on backend is very shallow :-D')
            alert(err)
          })
      })

      this.setState({ importing: false })
    })
  }

  onDeleteGuest = guestId => {
    const mutation = graphql`
      mutation EventsShowDeleteInviteeMutation($input: DeleteGuestByIdInput!) {
        deleteGuestById(input: $input) {
          clientMutationId
        }
      }
    `

    const variables = {
      input: {
        id: guestId
      }
    }

    return new Promise((resolve, reject) => {
      commitMutation(
        environment, {
          mutation,
          variables,
          onCompleted: (rsp, err) => this.setState({ lastUpdatedAt: new Date() }),
          onError: err => reject(err)
        }
      )
    })
  }

  render () {
    return (
      <QueryRenderer
        environment={environment}
        query={graphql`
          query EventsShowQuery($id: Uuid!) {
            event: eventById(id: $id) {
              title
              ...Event_event
              hosts: hostsByEventId {
                nodes {
                  user: userByUserId {
                    id
                    firstName
                    pictureUrl
                    pictureThumbnailUrl
                  }
                }
              }
              guests: guestsByEventId {
                ...GuestsList_guests
              }
            }
          }
        `}
        variables={{
          id: this.props.match.params.id,
          lastUpdatedAt: this.state.lastUpdatedAt
        }}
        render={({error, props}) => {
          if (error) return <Error error={error} />
          if (!props) return <Loader />

          return (
            <div className='my-3 my-md-5'>
              <div className='container'>
                <div className='row'>
                  <div className='col-lg-6'>
                    <div className='card'>
                      <div className='card-header'>
                        <h3 className='card-title'>{props.event.title}</h3>
                        <div className='card-options'>
                          <Button bsStyle='success' onClick={this.onEdit}>
                            Edit
                          </Button>
                        </div>
                      </div>
                      <div className='card-body'>
                        <Event event={props.event} />

                        <h5>Hosts</h5>
                        <p>{props.event.hosts.nodes.map(n => n.user.firstName).join(', ') || 'None'}</p>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <div className='card'>
                      <div className='card-header'>
                        <h3 className='card-title'>Invitations</h3>
                      </div>
                      <div className='card-body'>
                        <GuestsList guests={props.event.guests} onDelete={this.onDeleteGuest} />

                        <hr />

                        <h4>Import Invitee List</h4>

                        <Form horizontal>
                          <p className='small'>
                            Paste list of users into textarea below and press IMPORT button. Do *not* include any headers from Excel sheet and only copy the rows and columns required. Please check out <a href='https://cl.ly/mfTO'>https://cl.ly/mfTO</a>.
                          </p>
                          <p className='small'>
                            Columns must be name, fathers name, fathers emails, mothers name, mothers email.
                          </p>
                          <FormGroup>
                            <Col sm={12}>
                              <FormControl name='importList' componentClass='textarea' style={{ height: 200 }} value={this.state.importList} onChange={this.handleChange} />
                            </Col>
                          </FormGroup>
                        </Form>

                        <Button bsStyle='warning' onClick={this.onImport} disabled={this.state.importing}>
                          Import
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }}
      />
    )
  }
}

export { EventsShow }
