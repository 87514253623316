import graphql from 'babel-plugin-relay/macro'
import { truncate } from 'lodash'
import { OpenAI } from 'openai'
import React, { Component } from 'react'
import { Form } from 'react-bootstrap'
import { commitMutation, createFragmentContainer } from 'react-relay'

import environment from '../Environment'
import { slugify, theme } from '../helpers'

const sanitize = ({ title, description, body, keywords, path, language }) => ({
  title,
  description,
  body,
  keywords,
  path,
  language
})

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
})

class Page extends Component {
  constructor (props) {
    super(props)

    this.state = {
      generatingDescription: false,
      form: {
        ...sanitize(props.page || {})
      }
    }
  }

  create = ({ partnerId }) => {
    return new Promise((resolve, reject) => {
      commitMutation(environment, {
        mutation: graphql`
          mutation PageCreateMutation($input: PageInput!) {
            createPage(page: $input) {
              page {
                id
              }
            }
          }
        `,
        variables: {
          input: {
            ...this.state.form,
            ownerId: partnerId,
            ownerType: 'PARTNER',
            path: `/${slugify(this.state.form.title)}`
          }
        },
        onCompleted: (rsp, errors) => {
          if (errors) {
            console.log('failed', errors)
            reject(errors)
          } else {
            resolve(rsp)
          }
        },
        onError: err => {
          console.log('errored', err)
          reject(err)
        }
      })
    })
  }

  update = () => {
    return new Promise((resolve, reject) => {
      commitMutation(environment, {
        mutation: graphql`
          mutation PageUpdateMutation($id: Uuid!, $pagePatch: PagePatch!) {
            updatePageById(id: $id, pagePatch: $pagePatch) {
              page {
                id
              }
            }
          }
        `,
        variables: {
          id: this.props.page.id,
          pagePatch: {
            ...this.state.form
          }
        },
        onCompleted: (rsp, errors) => {
          if (errors) {
            console.log('failed', errors)
            reject(errors)
          } else {
            resolve(rsp)
          }
        },
        onError: err => {
          console.log('errored', err)
          reject(err)
        }
      })
    })
  }

  delete = () => {
    return new Promise((resolve, reject) => {
      commitMutation(environment, {
        mutation: graphql`
          mutation PageDeleteMutation($id: Uuid!) {
            deletePageById(id: $id) {
              page {
                id
              }
            }
          }
        `,
        variables: {
          id: this.props.page.id
        },
        onCompleted: (rsp, errors) => {
          if (errors) {
            console.log('failed', errors)
            reject(errors)
          } else {
            resolve(rsp)
          }
        },
        onError: err => {
          console.log('errored', err)
          reject(err)
        }
      })
    })
  }

  isNew = () => this.props.page === null || this.props.page.id === null

  saveChanges = () =>
    this.isNew()
      ? this.create({ partnerId: this.props.partnerId })
      : this.update()

  handleChange = page => {
    const { name, value } = page.target
    this.setState({ form: { ...this.state.form, [name]: value } })
  }

  renderField = (title, key, opts = {}) => {
    return (
      <Form.Group>
        <Form.Label style={theme.styles.label}>{title}</Form.Label>
        <Form.Control
          name={key}
          {...opts}
          placeholder={title}
          value={this.state.form[key] || ''}
          onChange={this.handleChange}
        />
      </Form.Group>
    )
  }

  renderHeader = title => <h5 style={theme.styles.header}>{title}</h5>

  render () {
    const { page, mode } = this.props

    const handleDescriptionGeneration = async e => {
      e.preventDefault()

      this.setState({ generatingDescription: true })

      const prompt = truncate(this.state.form.body, 1000)

      const response = await openai.chat.completions.create({
        model: 'gpt-3.5-turbo',
        temperature: 0.7,
        top_p: 1,
        frequency_penalty: 0.2,
        presence_penalty: 0,
        messages: [
          {
            role: 'system',
            content: 'You are a highly skilled writer capable of writing detailed meta descriptions for website pages. Output only descriptions as a summary of what is given to you. No quotations or other special characters' // prettier-ignore
          },
          { role: 'user', content: prompt }
        ]
      })

      if (!response?.choices) {
        console.log('-- no response', response)
        this.setState({ generatingDescription: false })
        return null
      }

      const output = response.choices
        .map(c => c.message.content)
        .join('\n')
        .trim()

      this.setState({
        generatingDescription: false,
        form: {
          ...this.state.form,
          description: output
        }
      })
    }

    if (page && !mode) {
      return <div>hmm</div>
    } else {
      return (
        <Form horizontal>
          <Form.Group>
            <Form.Label style={theme.styles.label}>Title *</Form.Label>
            <Form.Control
              name='title'
              placeholder='Title'
              required
              value={this.state.form.title || ''}
              onChange={this.handleChange}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label style={theme.styles.label}>Body</Form.Label>
            <Form.Control
              style={{ height: 240, fontFamily: 'monospace', fontSize: 10 }}
              name='body'
              as='textarea'
              placeholder='Body'
              value={this.state.form.body || ''}
              onChange={this.handleChange}
            />
          </Form.Group>

          {this.renderField('Path', 'path')}
          {this.renderField('Language', 'language')}

          {this.renderHeader('Meta')}
          <Form.Group>
            <Form.Label style={theme.styles.label}>Description</Form.Label>
            <Form.Control
              style={{ height: 64 }}
              name='description'
              as='textarea'
              placeholder='Description'
              value={this.state.form.description || ''}
              onChange={this.handleChange}
            />
            <button
              style={{ marginTop: '8px' }}
              className='btn btn-secondary ml-auto'
              onClick={handleDescriptionGeneration}
              disabled={this.state.generatingDescription}
            >
              {this.state.generatingDescription
                ? 'Generating description...'
                : 'Generate description'}
            </button>
          </Form.Group>

          {this.renderField('Keywords', 'keywords')}
        </Form>
      )
    }
  }
}

const PageContainer = createFragmentContainer(Page, {
  page: graphql`
    fragment Page_page on Page {
      id
      title
      description
      body
      keywords
      path
      language
    }
  `
})

export { PageContainer as Page }
